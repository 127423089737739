.saps-dealer-title {
  font-size: 45px;
  font-weight: bold;
  line-height: 0.93;
  color: map-get($color-scheme-list, black);
  margin-bottom: 46px;
  margin-top: 16px;
}

.saps-dealer-seller {
  position: relative;
  display: flex;
  align-items: center;

  .saps-dealer-seller__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
  }

  .saps-dealer-seller__info {
    position: relative;
    margin-left: 75px;
    font-size: 15px;
    line-height: 18px;
  }
}

.saps-dealer-contact-form {
  padding: 40px;

  @include media-breakpoint-up(md) {
    margin-left: -40px;
    margin-right: -40px;
  }
}

.saps-dealer-detail-images {
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;

  .swiper-pagination {
    position: absolute;
    bottom: 0;
  }
}

.saps-dealer-detail__image {
  display: block;
  width: 100%;
}

.saps-dealer-map-input {
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: map-get($color-scheme-list, white);
  padding: 15px 30px;

  &::placeholder {
    opacity: 1;
    color: map-get($color-scheme-list, grey-300);
  }

  @include media-breakpoint-down(sm) {
    &.saps-dealer-map-input--map-search {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}
