#wrapper {
  padding-top: 0;
}

.portlet {
  margin: 0;
}

.portlet-column-content {
  &.empty {
    padding: 0;
  }
}

.portlet-content-editable,
.portlet-content {
  padding: 0;
}

.portlet-decorate .portlet-content {
  background: none;
  border: none;
}

.portlet-layout .portlet-header {
  margin-bottom: 0;
}

body.signed-in {
  padding-top: 0;

  .saps-header {
    position: relative;
    z-index: 0;
    margin-bottom: 40px;
  }

  .portlet-topper {
    padding: 3px 12px 3px 24px;
  }

  .portlet {
    margin: 0;
    padding: 0;
    min-height: 20px;
    min-width: 180px;
    border: dotted #f0f0f0 1px;
    border-radius: 8px;
    background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05) 0px,
      rgba(255, 255, 255, 0.05) 2px,
      rgba(0, 0, 0, 0.05) 2px,
      rgba(0, 0, 0, 0.05) 4px
    );

    .portlet-topper {
      border: solid transparent 1px;
      border-radius: 8px 8px 0 0;
    }

    .portlet-content {
      border: solid transparent 1px;
      border-radius: 0 0 8px 8px;
    }

    &:hover {
      .portlet-topper,
      .portlet-content {
        border-color: map-get($color-scheme-list, secondary);
      }
    }
  }

  .portlet-layout {
    .portlet-header {
      margin-top: 1rem;
    }
  }

  .portlet-boundary {
    margin: 15px 2px;
  }

  .saps-footer .saps-footer-top {
    position: relative;
  }

  .portlet-column-content.empty {
    position: relative;
    margin: 10px;
    padding: 30px 0;
    background-image: repeating-linear-gradient(
      -45deg,
      #ffffff 0px,
      #ffffff 2px,
      #f5f5f5 2px,
      #f5f5f5 4px
    );
    border-radius: 8px;
    border: dotted map-get($color-scheme-list, secondary) 1px;
    opacity: 0.7;

    &::after {
      display: block;
      position: absolute;
      width: 100%;
      top: 13px;
      text-align: center;
      font-size: 24px;
      line-height: 30px;
      color: map-get($color-scheme-list, secondary);
      content: 'portlet dropzone';
      opacity: 0.5;
    }
  }

  .saps-hp-layout-top-row {
    padding: 0;

    .portlet-column-content,
    .portlet-journal-content,
    .portlet-content,
    .portlet-content-container,
    .portlet-body,
    .journal-content-article,
    .portlet {
      height: auto;
    }
  }

  .saps-hp-layout-top-row {
    .saps-hp-layout-top-row__col-right {
      .saps-hp-layout-top-row__col-right-content {
        &::before {
          display: none;
        }
      }
    }
  }

  .saps-hp-layout-top-row .saps-hp-layout-top-row__col-right {
    .saps-hp-layout-top-row__col-right-content {
      padding: 0;
      margin: 0;
    }
  }

  .saps-hp-layout-polygons {
    display: none;
  }
}
