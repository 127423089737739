.saps-recommended-models__wrapper {
  @include make-container-max-widths();
  margin-left: auto;
  margin-right: auto;

  //Homepage specific margin
  margin-top: 80px;
  margin-bottom: 120px;
}

.saps-recommended-models {
  @include make-row();

  @include media-breakpoint-down(sm) {
    margin-top: 30px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 64px;
  }
}

.saps-recommended-models__item {
  //Tile boundaries for absolute __item-link
  position: relative;

  //10 columns instead of 12 because of 5 tiles layout
  $recommended-models-grid-columns--md: 10;

  //Same height for all images in row
  display: flex;
  flex-flow: column;

  @include make-col-ready();

  @include media-breakpoint-down(sm) {
    @include make-col(12);

    &:nth-child(n + 2) {
      margin-top: 42px;
    }
  }

  @include media-breakpoint-up(md) {
    @include make-col(2, $recommended-models-grid-columns--md);

    &:nth-child(n + 6) {
      margin-top: 42px;
    }
  }
}

.saps-recommended-models__image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  flex: 1 1 auto; //Fills image to tile height even if it failed loading
}

.saps-recommended-models__name {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  min-height: 32px; //Prevents recalculating layout when loading value
  padding-bottom: 8px;
  white-space: nowrap; //Design specific rule
}

.saps-recommended-models__numberOfItems {
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  line-height: 15px;
  min-height: 15px; //Prevents recalculating layout when loading value
}

.saps-recommended-models__item-link {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
