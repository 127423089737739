.saps-cars-comparison {
  display: flex;
  position: relative;
  @include media-breakpoint-down(sm) {
    padding-bottom: 150px;
    .saps-toolbar-car__cross-icon {
      top: 5px;
      left: auto;
      right: 5px;
    }
  }
  @include media-breakpoint-down(md) {
    .swiper-container {
      margin: 0;
    }
  }

  .swiper-pagination-bullets {
    display: none;
  }
}

.saps-cars-comparison-toolbar {
  display: block;
  padding-top: 200px;
  flex: 0 0 150px;
  max-width: 150px;
  padding-left: 0px;
  padding-right: 10px;
  box-shadow: 0 -20px 18px #c3c3c3;
  @include media-breakpoint-down(sm) {
    box-shadow: none;
    padding-right: 0px;
    .saps-cars-comparison__equipment-wrapper {
      padding-left: 20px;
    }
  }

  @include media-breakpoint-up(sm) {
    flex: 0 0 320px;
    max-width: 320px;
    padding-left: 20px;
    padding-top: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 100px;
    flex: 0 0 380px;
    max-width: 380px;
  }
}

.saps-cars-comparison-toolbar__heading {
  @include media-breakpoint-up(sm) {
    font-size: 30px;
    line-height: 1.13;
    font-weight: 600;
    color: map-get($color-scheme-list, 'grey-500');
    margin-bottom: 17px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 40px;
    font-weight: 300;
    color: map-get($color-scheme-list, orange);
    text-align: center;
    padding-top: 25px;
    padding-bottom: 20px;
  }
}

.saps-cars-comparison-car {
  width: 150px;
  margin-top: 120px;
  min-width: 150px;

  @include media-breakpoint-up(sm) {
    margin-top: 80px;
    width: 290px;
    min-width: 290px;
  }
}

.saps-cars-comparison-car__header-wrapper {
  @include media-breakpoint-up(md) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.saps-cars-comparison-car__header {
  position: relative;
}

.saps-cars-comparison-car__header:before {
  .saps-cars-comparison-car:hover & {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    box-shadow: 0 0 18px #000000;
    z-index: -1;
  }
}

.saps-cars-comparison-car__header:after {
  .saps-cars-comparison-car:hover & {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    box-shadow: 0 0 18px #000000;
    z-index: -1;
  }
}

.saps-cars-comparison-toolbar__header,
.saps-cars-comparison-car__header {
  @include media-breakpoint-up(sm) {
    height: 450px;
  }
}

.saps-cars-comparison--print {
  max-width: 160px;
}

@include media-breakpoint-down(sm) {
  .saps-cars-comparison--print {
    position: absolute;
    top: calc(100% - 50px);
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: none;
  }
}

.saps-cars-comparison-car__header-main-info {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;

  @include media-breakpoint-up(md) {
    .saps-cars-comparison-car:hover & {
      background: #fff;
    }
  }
}

.saps-cars-comparison-car__image-wrapper {
  @include media-breakpoint-up(sm) {
    width: 100%;
    height: 80px;
    position: relative;
    margin: auto;
    width: 270px;
    height: 203px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.saps-cars-comparison-car__image {
  display: block;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    .saps-cars-comparison-car:hover & {
      opacity: 0.3;
    }
  }
}

.saps-cars-comparison-car__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;

  .saps-cars-comparison-car:hover & {
    display: flex;
  }
}

.saps-cars-comparison__equipment-wrapper {
  padding-left: 10px;
  padding-right: 10px;
  border-top: 1px solid map-get($color-scheme-list, 'grey-200');
  position: relative;
  z-index: 1;

  .saps-cars-comparison-toolbar & {
    border-top: 1px solid transparent;
  }
}

.saps-cars-comparison__equipment-container {
  @include media-breakpoint-down(sm) {
    .saps-cars-comparison__equipment-wrapper {
      border: none;
      &:nth-child(odd) {
        background-color: map-get($color-scheme-list, white);
      }

      &:nth-child(even) {
        background-color: map-get($color-scheme-list, grey-100);
      }
    }
  }
}

.saps-cars-comparison__equipment-container--inverted {
  @include media-breakpoint-down(sm) {
    .saps-cars-comparison__equipment-wrapper {
      border: none;
      &:nth-child(even) {
        background-color: map-get($color-scheme-list, white);
      }

      &:nth-child(odd) {
        background-color: map-get($color-scheme-list, grey-100);
      }
    }
  }
}

.saps-cars-comparison__equipment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  height: 80px;
  //white-space: nowrap;

  @include media-breakpoint-down(sm) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @include media-breakpoint-up(sm) {
    height: 38px;
  }

  .saps-cars-comparison-car & {
    color: map-get($color-scheme-list, 'grey-500');
    font-weight: 600;
  }

  .saps-cars-comparison-toolbar & {
    color: map-get($color-scheme-list, 'grey-400');
    font-weight: 500;
    padding-left: 0;
    padding-right: 0;
    justify-content: flex-start;
  }
  &.saps-cars-comparison-car__dealer {
    color: map-get($color-scheme-list, orange);
  }
}

@include media-breakpoint-up(md) {
  .saps-cars-comparison__equipment {
    .saps-cars-comparison-car:hover & {
      background: #fff;
    }
  }

  .saps-cars-comparison__equipment:before {
    .saps-cars-comparison-car:hover & {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      box-shadow: 0 0 18px #000000;
      z-index: -1;
    }
  }

  .saps-cars-comparison__equipment:after {
    .saps-cars-comparison-car:hover & {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      box-shadow: 0 0 18px #000000;
      z-index: -1;
    }
  }
}

.saps-cars-comparison-car__checkmark-icon {
  width: 16px;
}

.saps-cars-comparison-car__cross-icon {
  width: 14px;
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  display: none;

  .saps-cars-comparison-car:hover & {
    display: block;
  }

  @include media-breakpoint-down(sm) {
    .saps-cars-comparison-car {
      display: block;
    }
  }
}

.saps-cars-comparison-car__make-name {
  padding-top: 35px;
}

.saps-cars-comparison-car__make-name,
.saps-cars-comparison-car__model-name {
  font-size: 22px;
  line-height: 1;
  font-weight: 600;
  color: map-get($color-scheme-list, 'grey-500');
}

.saps-cars-comparison-car__model-level {
  font-size: 13px;
  letter-spacing: 0.3px;
  color: map-get($color-scheme-list, 'grey-500');
  padding-top: 6px;
}

.saps-cars-comparison-car__price-with-vat {
  font-size: 22px;
  line-height: 1;
  font-weight: 600;
  color: map-get($color-scheme-list, 'grey-500');

  @include media-breakpoint-up(md) {
    padding-top: 13px;
  }
}

.saps-cars-comparison-car__price {
  font-size: 13px;
  line-height: 1;
  color: map-get($color-scheme-list, 'grey-500');
  padding-top: 4px;
}

.saps-cars-comparison-car__dealer {
  color: map-get($color-scheme-list, orange);
  text-decoration: none;
  @include media-breakpoint-up(sm) {
    display: block;
    font-size: 13px;
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

.saps-cars-comparison-car__cebia {
  display: flex;
}

.saps-cars-comparison-toolbar__backbutton {
  @include media-breakpoint-down(sm) {
    position: absolute;
    left: 10px;
    top: 150px;
  }
}

.saps-cars-comparison--empty-list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 20px;
  text-align: center;
  h2 {
    font-size: 2em;
    margin: auto;
  }
}

.saps-cars-comparison-car__remove {
  width: 25px;
  position: absolute;
  right: 15px;
  top: 5px;
  background-color: map-get($color-scheme-list, orange);
  border-radius: 100%;
  padding: 4px;
}
