$basic-filter-mobile-margin: 25px;

.saps-basic-filter {
  margin-left: 0;
  margin-right: 0;

  .saps-input-select-label-wrapper {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .saps-input-select-label {
    display: block;
    cursor: pointer;
    margin-bottom: 0px;

    .saps-input-select-icon-checkbox {
      display: none;
    }
  }

  .saps-basic-filter__body-type-icon,
  [class*='saps-basic-filter__body-type-icon'] {
    .saps-basic-filter__body-type-icon-path,
    [class*='saps-basic-filter__body-type-icon-path'] {
      fill: map-get($color-scheme-list, grey-100);
      transition: all $transition-time;
    }
  }

  .saps-input-select-icon-checkbox {
    &:checked ~ .saps-basic-filter__body-type-icon,
    &:checked ~ [class*='saps-basic-filter__body-type-icon'] {
      .saps-basic-filter__body-type-icon-path,
      [class*='saps-basic-filter__body-type-icon-path'] {
        fill: map-get($color-scheme-list, secondary);
      }
    }
  }

  .rc-slider-mark-text {
    &:last-child {
      left: 100% !important;
    }
  }

  .saps-basicfilter-main-dropdown {
    .saps-inputlist-dropdown__toggle {
      @include media-breakpoint-up(md) {
        line-height: 1;
        padding: 0;
        border: none;
      }

      .saps-inputlist-dropdown__toggle-text,
      .saps-inputlist-dropdown__toggle-text.saps-inputlist-dropdown__toggle-text--radio {
        font-size: 22px;
        font-weight: bold;
        flex: 0 0 auto;
      }

      .saps-inputlist-dropdown__toggle-icon-svg {
        width: 20px;
        height: auto;
      }
    }
  }

  .saps-basicfilter-purpose-dropdown {
    .saps-inputlist-dropdown__toggle {
      border-color: map-get($color-scheme-list, grey-500);

      .saps-inputlist-dropdown__toggle-text,
      .saps-inputlist-dropdown__toggle-text.saps-inputlist-dropdown__toggle-text--radio {
        font-size: 22px;
        font-weight: bold;
        flex: 0 0 auto;

        @include media-breakpoint-up(md) {
          font-size: 13px;
          font-weight: 300;
          flex: 1 0 auto;
        }
      }
    }
  }

  .saps-basic-filter--mobile {
    @media only screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .saps-basicfilter-purpose-label {
    // hack for small contrast
    color: #aaa !important;
    font-size: 13px;
    font-weight: normal;
    color: map-get($color-scheme-list, grey-400);
    display: flex;
    @include media-breakpoint-up(md) {
      font-size: 10px;
    }
  }

  .saps-basicfilter-purpose-checkmark {
    display: none;
  }

  .saps-basicfilter-purpose-tab-label {
    white-space: nowrap;
    position: absolute;
    bottom: 0;
    left: 50%;
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    transform: translateX(-50%);
    transition: all $transition-time;
    color: map-get($color-scheme-list, text);

    @include media-breakpoint-up(md) {
      font-size: 13px;
    }
  }

  &.saps-basic-filter--basic-view {
    margin-left: $basic-filter-mobile-margin;
    margin-right: $basic-filter-mobile-margin;

    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
    }

    @include media-breakpoint-up(md) {
      width: 440px;
      height: 620px;
      margin-left: auto;
      margin-right: auto;
      background: transparent;
    }

    .saps-hr--basic-filter {
      border-color: map-get($color-scheme-list, grey-500);

      @include media-breakpoint-up(md) {
        border-color: map-get($color-scheme-list, grey-100);
      }
    }

    .saps-basicfilter-purpose-tab-label {
      color: map-get($color-scheme-list, white);

      @include media-breakpoint-up(md) {
        color: map-get($color-scheme-list, text);
      }
    }

    .saps-icontab-title--active {
      opacity: 1;

      @include media-breakpoint-up(md) {
        .saps-basicfilter-purpose-checkmark {
          display: block;
        }

        .saps-basicfilter-purpose-tab-label {
          color: map-get($color-scheme-list, secondary);
        }
      }
    }

    .saps-basic-filter-main-tabs {
      margin-left: -$basic-filter-mobile-margin;
      margin-right: -$basic-filter-mobile-margin;
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
        margin-left: 0;
      }
    }

    .saps-tab-title {
      margin-left: $basic-filter-mobile-margin;
      margin-right: $basic-filter-mobile-margin;

      &.saps-tab-title--basic-filter {
        color: map-get($color-scheme-list, text);

        &.saps-tab-title--active {
          color: map-get($color-scheme-list, white);
          border-color: map-get($color-scheme-list, white);
        }

        &:last-child {
          margin-right: $basic-filter-mobile-margin;
        }

        @include media-breakpoint-up(md) {
          color: map-get($color-scheme-list, grey-200);

          &:hover {
            color: map-get($color-scheme-list, text);
          }

          &.saps-tab-title--active {
            color: map-get($color-scheme-list, text);
            border-color: map-get($color-scheme-list, text);
          }
        }
      }
    }

    .saps-basicfilter-main-dropdown {
      .saps-inputlist-dropdown__toggle {
        .saps-inputlist-dropdown__toggle-text,
        .saps-inputlist-dropdown__toggle-text.saps-inputlist-dropdown__toggle-text--radio {
          color: map-get($color-scheme-list, white);

          @include media-breakpoint-up(md) {
            color: map-get($color-scheme-list, text);
          }
        }
      }
    }

    .saps-slider-range {
      color: map-get($color-scheme-list, white);

      @include media-breakpoint-up(md) {
        color: map-get($color-scheme-list, text);
      }
    }

    .saps-icontab-title__icon {
      border-color: map-get($color-scheme-list, white);
    }

    [class*='saps-tab-icon-artwork'] {
      fill: map-get($color-scheme-list, white);
      transition: all $transition-time;

      @include media-breakpoint-up(md) {
        fill: map-get($color-scheme-list, text);
      }
    }

    .saps-icontab-title {
      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .saps-icontab-title--active {
      [class*='saps-tab-icon-artwork'] {
        fill: map-get($color-scheme-list, white);
      }

      @include media-breakpoint-up(md) {
        [class*='saps-tab-icon-artwork'] {
          fill: map-get($color-scheme-list, secondary);
        }
      }
    }

    .saps-basicfilter-purpose-dropdown {
      .saps-inputlist-dropdown__toggle {
        .saps-inputlist-dropdown__toggle-text,
        .saps-inputlist-dropdown__toggle-text.saps-inputlist-dropdown__toggle-text--radio {
          color: map-get($color-scheme-list, white);

          @include media-breakpoint-up(md) {
            color: map-get($color-scheme-list, text);
          }
        }
      }
    }

    .saps-basicfilter-main-dropdown {
      .saps-inputlist-dropdown__toggle {
        border: none;
      }
    }
  }

  &.saps-basic-filter--wide-view {
    background-color: #ffffff;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

.saps-label--basic-filter {
  color: map-get($color-scheme-list, grey-400);

  @include media-breakpoint-up(md) {
    color: map-get($color-scheme-list, grey-200);
  }
}

.saps-slider-label--basic-filter {
  color: map-get($color-scheme-list, grey-400);

  @include media-breakpoint-up(md) {
    color: map-get($color-scheme-list, grey-350);
  }
}
.saps-filter-button-align {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}
