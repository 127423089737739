$font-family: SKODANext, sans-serif;
$transition-time: 150ms;

$header-height: (
  mobile: 60px,
  desktop: 80px,
);

$color-scheme-list: (
  primary: #009c00,
  secondary: #ef7d00,
  secondary-hover: #dd7400,
  white: #ffffff,
  black: #000000,
  text: #4a4a4a,
  orange: #ef7d00,
  alertRed: #ef2600,
  grey-600: #878787,
  grey-500: #4a4a4a,
  grey-400: #707070,
  grey-410: #777777,
  grey-350: #aaaaaa,
  grey-300: #cecece,
  grey-200: #b8b8b8,
  grey-100: #eaeaea,
  grey-50: #f7f7f7,
);

$spacing-list: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
  90,
  95,
  100,
  175,
  250,
  0
);

$body-bgr-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 700" height="700" width="1440"%3e%3cdefs%3e%3clinearGradient gradientUnits="userSpaceOnUse" x1="234" y1="0" x2="1440" y2="700" id="a"%3e%3cstop stop-color="%23f0f0f0" offset="0" /%3e%3cstop stop-color="rgba(238,238,238,0.33)" offset="1" /%3e%3c/linearGradient%3e%3clinearGradient gradientUnits="userSpaceOnUse" x1="700" y1="0" x2="700" y2="700" id="b"%3e%3cstop stop-color="%23f0f0f0" offset="0" /%3e%3cstop stop-color="%23f7f7f7" offset="1" /%3e%3c/linearGradient%3e%3c/defs%3e%3cg fill-rule="evenodd"%3e%3cpath d="M 945,0 L 1440,0 1440,700 635,700 z" fill="url(%23b)"/%3e%3cpath d="M234,0 L 1440,0 1440,700 z" fill="url(%23a)" /%3e%3c/g%3e%3c/svg%3e');

%saps-slider-dots {
  display: flex;
  justify-content: center;
}

%saps-slider-dot {
  display: block;
  position: relative;
  flex: 0 0 auto;
  list-style-type: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: none;
  opacity: 1;
  margin: 0;
}

%saps-slider-dot-after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background-color: map-get($color-scheme-list, grey-200);
  border-radius: 50%;
  transition: all $transition-time;
  margin-left: -2px;
  margin-top: -2px;
  content: '';
}

%saps-slider-dot-active-after {
  width: 9px;
  height: 9px;
  background-color: map-get($color-scheme-list, text);
  margin-left: -4.5px;
  margin-top: -4.5px;
}

%saps-slider-dot-hover-after {
  width: 9px;
  height: 9px;
  background-color: map-get($color-scheme-list, text);
  margin-left: -4.5px;
  margin-top: -4.5px;
  background-color: map-get($color-scheme-list, secondary);
}

%saps-close-cross {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 64px;
  height: 60px;
  cursor: pointer;
  z-index: 1;

  &::before,
  &::after {
    position: absolute;
    top: 30px;
    right: 40px;
    display: block;
    width: 1px;
    height: 15px;
    background-color: map-get($color-scheme-list, secondary);
    content: '';
    transition: all $transition-time;
    transform-origin: center center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: map-get($color-scheme-list, text);
    }
  }
}

%saps-close-cross-large {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 3;

  &::before,
  &::after {
    position: absolute;
    top: 23px;
    right: 44px;
    display: block;
    width: 1px;
    height: 42px;
    background-color: map-get($color-scheme-list, text);
    content: '';
    transition: all $transition-time;
    transform-origin: center center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: map-get($color-scheme-list, secondary);
    }
  }
}
