.saps-car-detail-header {
  margin-bottom: 25px;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  .saps-car-detail-header__top {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 15px;
    }
  }

  .saps-car-detail-header__title {
    font-size: 38px;
    font-weight: bold;
    line-height: 0.93;
    color: map-get($color-scheme-list, black);
    text-align: center;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      font-size: 45px;
      text-align: left;
      margin-bottom: 0;
    }

    .saps-car-detail-header__subtitle {
      display: block;
      font-weight: 300;
      padding-top: 5px;
    }
  }

  .saps-car-detail-header__price {
    display: block;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: normal;
    color: map-get($color-scheme-list, text);
    margin-bottom: 7px;
    text-align: center;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }

  .saps-car-detail-header__price-no-vat {
    display: block;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.33;
    color: map-get($color-scheme-list, text);
    text-align: center;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
}

.saps-car-detail-header__arrow {
  position: relative;
  top: 9px;
}

.saps-car-detail__model-level {
  font-size: 15px;
  font-weight: 300;
  color: map-get($color-scheme-list, grey-500);
}

.saps-car-detail__equipment {
  font-size: 13px;
  padding-left: 30px;
  color: map-get($color-scheme-list, grey-400);
  font-weight: 400;
}

.saps-car-detail-cebia {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .saps-car__cebia-star {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .saps-car-detail-tabs__table & {
    margin: 0;
  }
}

.saps-car-detail-cebia__text-link {
  font-size: 10px;
  line-height: 1;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: map-get($color-scheme-list, secondary);
  text-transform: uppercase;
}

.saps-car-detail-dealer {
  font-size: 18px;
  font-weight: bold;
  color: map-get($color-scheme-list, secondary);
}

.saps-car-detail-dealer__link {
  cursor: pointer;
}

.saps-car-detail-feature__title {
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6;
  color: map-get($color-scheme-list, secondary);
  margin-top: 3px;
}

.saps-car-detail-feature__info {
  display: block;
  font-size: 10px;
  font-weight: 300;
  color: map-get($color-scheme-list, secondary);
}

[class*='saps-car-detail__feature-icon'] {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.saps-car-detail-main-features__item {
  min-height: 40px;
  margin-bottom: 20px;
}

.saps-car-detail-main-features__title {
  font-family: SKODANext;
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: map-get($color-scheme-list, grey-350);
  margin-bottom: 9px;
}

.saps-car-detail-main-features__value {
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
}

.saps-car-detail__back {
  display: block;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: map-get($color-scheme-list, secondary);
  cursor: pointer;

  @include media-breakpoint-up(md) {
    display: inline-block;
    margin-bottom: 40px;
  }
}

.saps-car-detail-cebia {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  .saps-car__cebia-star {
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.saps-car-detail-cebia__text-link {
  cursor: pointer;
  text-decoration: underline;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: map-get($color-scheme-list, secondary);
  text-transform: uppercase;
}

.saps-car-detail-dealer {
  font-size: 18px;
  font-weight: bold;
  color: map-get($color-scheme-list, secondary);
}

.saps-car-detail-features__title {
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
}

.saps-car-detail-features__value-wrapper {
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.saps-car-detail-features__value {
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
}

.saps-car-detail__border {
  display: block;
  width: 100%;
  height: 1px;
  background-color: map-get($color-scheme-list, grey-100);
}

.saps-car-detail-tabs__car-condition {
  font-size: 18px;
  font-weight: 600;
  color: map-get($color-scheme-list, grey-500);
  margin-bottom: 20px;
}

.saps-car-detail-tabs__car-condition-text,
.saps-car-detail-tabs__disclaimer {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  color: map-get($color-scheme-list, grey-500);
}

.saps-car-detail-tabs__table-wrapper {
  background: #fff;
  padding: 8px 25px;
}

.saps-car-detail-tabs__table {
  border-collapse: collapse;
  width: 100%;
}

.saps-car-detail-tabs__th {
}

.saps-car-detail-tabs__th--vertical {
  padding: 10px 15px;
  padding-left: 0;
  border-bottom: 1px solid map-get($color-scheme-list, grey-100);

  .saps-car-detail-tabs__tr:last-child & {
    border-bottom: none;
  }
}

.saps-car-detail-tabs__td {
  padding: 10px 15px;
  border-bottom: 1px solid map-get($color-scheme-list, grey-100);

  .saps-car-detail-tabs__tr:last-child & {
    border-bottom: none;
  }
}

.saps-car-detail-bottom-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 100px;
  background-color: map-get($color-scheme-list, white);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;

  .saps-car-detail-bottom-toolbar-calculator-wrapper {
    &::after {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 30px;
      background-color: #f1f1f1;
      content: '';
      z-index: -1;

      @include media-breakpoint-up(xl) {
        width: 300px;
      }
    }
  }
  .saps-car-detail-bottom-toolbar-container {
    height: 100%;
    background-image: linear-gradient(295deg, #f1f1f1 50%, #ffffff 180px, #ffffff);

    @include media-breakpoint-up(md) {
      background-image: linear-gradient(295deg, #f1f1f1 400px, #ffffff 400px, #ffffff);
    }
  }

  .saps-car-detail-bottom-toolbar-comparison--active {
    cursor: pointer;
    opacity: 1;
    z-index: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .saps-car-detail-bottom-toolbar-comparison {
    cursor: pointer;
    opacity: 1;
    z-index: 1;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .saps-car-detail-bottom-toolbar-comparison-cancel {
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #ef7d00;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
  }

  .saps-financing-calc-wrapper {
    position: absolute;
    right: 0;
    top: 13px;
    background: none;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    transition: all $transition-time;
    z-index: 9;

    @include media-breakpoint-up(md) {
      right: 20px;
    }

    &.saps-financing-calc-wrapper--active {
      position: fixed;
      top: 0;
      height: 100vh;
      width: 100vw;
      max-height: 100vh;
      z-index: 11;
      background-color: #f1f1f1;
      text-align: center;

      @include media-breakpoint-up(md) {
        position: fixed;
        top: auto;
        bottom: 0;
        right: 22px;
        width: 725px;
        height: 1300px;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='20 20 720 491'%3E%3Cpath d='M104 20L20 511h720V20z' fill='%23f1f1f1' filter='drop-shadow(0 0 7px rgba(195, 195, 195, 0.5))'/%3E%3C/svg%3E");
        padding: 40px 40px 0 120px;
      }

      &.saps-financing-calc-wrapper--sk.saps-financing-calc-wrapper--active {
        @include media-breakpoint-up(md) {
          height: 557px;
        }
      }

      .saps-financing-calc-iframe {
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
        // margin-top: -42px;
        width: 100%;
        height: calc(100% + 80px);
        margin-bottom: 40px;
        left: 0;
        top: -80px;

        @include media-breakpoint-up(md) {
          max-width: unset;
          margin-bottom: 0;
        }
      }

      .saps-financing-calc-iframe-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 12;
        overflow: auto;

        // hiding iframe calc top result with this pseudo element on mobile resolution
        &::before {
          position: relative;
          height: 40px;
          background-color: #f1f1f1;
          content: '';
          display: block;
          content: '';
          z-index: 10;
        }

        @include media-breakpoint-up(md) {
          position: relative;
          width: 521px;
          height: 100%;
          overflow: hidden;

          &::before {
            display: none;
          }
        }
      }

      .saps-financing-calc-close {
        display: block;
        z-index: 15;
      }
    }
  }

  .saps-financing-calc-iframe-wrapper {
    position: relative;
    overflow: hidden;
    height: 52px;
    width: 170px;
    background: none;
    transition: all $transition-time;
  }

  .saps-financing-calc-wrapper--sk {
    .saps-financing-calc-iframe-wrapper {
      width: 154px;
      height: 44px;
    }

    .saps-financing-calc-iframe {
      top: 0;
    }
  }

  .saps-financing-calc-iframe {
    position: relative;
    top: -20px;
    left: 0;
    min-width: 240px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      width: 521px;
      height: 482px;
      z-index: 1;
    }
  }

  .saps-financing-calc-toggle {
    width: 225px;
    height: 80px;
    z-index: 10;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      right: 15px;
    }
  }

  .saps-financing-calc-close {
    display: none;
    top: 0;
    right: 0;
    @extend %saps-close-cross-large;

    @include media-breakpoint-up(md) {
      top: 20px;
      right: 15px;
    }
  }

  .saps-financing-calc-col {
    // width: 130px;
    // flex-basis: 130px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      // width: 400px;
      // flex-basis: 400px;
    }
  }
}
