.saps-hp-layout-top-row {
  position: relative;

  @include media-breakpoint-down(xs) {
    overflow: hidden;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 14%;
    display: flex;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 72px;
  }

  .saps-hp-layout-top-row__col-left {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    height: 100vh;
    margin-top: map-get($header-height, mobile) * -1;
    padding-top: map-get($header-height, mobile);

    @include media-breakpoint-up(md) {
      min-height: 520px;
      height: 520px;
      overflow: hidden;

      &::after {
        display: block;
        position: absolute;
        bottom: -1px;
        left: -1px;
        right: -1px;
        height: 100px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 768 72' height='72' width='768'%3e%3cpath d='M0 72h768V0z' fill='%23fff' fill-rule='evenodd'/%3e%3c/svg%3e");
        background-size: 100% auto;
        background-position: center bottom;
        background-repeat: no-repeat;
        content: '';
      }
    }

    @include media-breakpoint-up(lg) {
      flex: 0 0 48%;
      min-height: 0;
      height: auto;
      margin-top: 0;
      padding-top: 0;

      &::after {
        display: none;
      }
    }

    @include media-breakpoint-up(xl) {
      flex: 1 0 58%;
    }

    .portlet-column-content,
    .portlet-journal-content,
    .portlet-content,
    .portlet-content-container,
    .portlet-body,
    .journal-content-article,
    .portlet {
      height: 100%;
    }
  }

  .saps-hp-layout-top-row__col-right {
    position: relative;
    padding-bottom: 60px;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      flex: 1 0 52%;
      z-index: 3;
      overflow: visible;
    }

    @include media-breakpoint-up(xl) {
      flex: 1 0 38%;
    }

    .saps-hp-layout__right-col-top-polygon {
      display: none;
      width: 100%;
      margin-bottom: -1px;

      @include media-breakpoint-up(md) {
        display: block;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .saps-hp-layout-top-row__col-right-content {
      position: relative;
      background-color: #202020;
      min-height: 150px;

      &::after {
        display: block;
        position: absolute;
        width: 55%;
        left: -5%;
        top: 100%;
        height: 50px;
        background-color: #202020;
        content: '';
        transform-origin: right bottom;
        transform: rotate(7deg);
        box-shadow: 0 1px 10px #202020;
        z-index: -1;
      }

      &::before {
        display: block;
        position: absolute;
        width: 55%;
        top: 100%;
        left: 50%;
        height: 50px;
        background-color: #202020;
        content: '';
        transform-origin: left bottom;
        transform: rotate(-7deg);
        box-shadow: 0 1px 10px #404040;
        z-index: -1;
      }

      @include media-breakpoint-up(md) {
        background-color: #ffffff;
        box-shadow: none;
        padding-top: 20px;
        padding-bottom: 40px;

        &::before,
        &::after {
          display: none;
        }
      }

      @include media-breakpoint-up(lg) {
        padding: 25px 0 0 0;
        margin-bottom: 17%;

        &::before {
          display: block;
          position: absolute;
          // hide potential top space over bg-image by negative position
          top: -2px;
          left: -144px;
          right: 0;
          bottom: -96px;
          width: auto;
          height: auto;
          background-color: transparent;
          background-size: 100% 100%;
          content: '';
          box-shadow: none;
          transform: none;
          background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" height="612" width="594"%3e%3cpath d="M77 0L0 612l594-51V0z" fill="%23fff" fill-rule="evenodd"/%3e%3c/svg%3e');
        }
      }

      @include media-breakpoint-up(xl) {
        padding: 25px 0 0 0;
        margin-bottom: 38.5%;

        &::before {
          bottom: -176px;
          background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 236 223" height="850" width="896"%3e%3cpath d="M31 0h205v178L0 223 31 0" fill="%23ffffff" fill-rule="evenodd"/%3e%3c/svg%3e');
        }
      }
    }
  }
}

.saps-hp-layout-polygons {
  display: none;
  position: absolute;
  left: 0;
  // hide potential bottom space behind image by negative position
  bottom: -1px;
  width: 100%;
  height: auto;
  min-height: 33%;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    width: 140%;
    left: auto;
    right: 0;
    display: block;
  }

  @include media-breakpoint-up(xl) {
    width: 100%;
  }

  .saps-hp-layout-polygon1 {
    fill: #f7f7f7;
  }

  .saps-hp-layout-polygon2 {
    fill: #e9e9e9;
  }
}
