.saps-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  height: map-get($header-height, mobile);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
  z-index: 5;

  @include media-breakpoint-up(lg) {
    border-bottom: solid map-get($color-scheme-list, grey-300) 1px;
    height: map-get($header-height, desktop);
    box-shadow: none;
  }

  .saps-header__logo {
    display: block;
    width: 140px;

    @include media-breakpoint-up(lg) {
      width: 180px;
    }

    @include media-breakpoint-up(xl) {
      width: 240px;
    }
  }
}

.saps-menu-toggle {
  display: block;
  padding: 8px 3px 8px 10px;

  .saps-menu-toggle__bars {
    position: relative;
    display: block;
    width: 20px;
    height: 3px;
    background-color: map-get($color-scheme-list, secondary);
    margin: 6px 0;

    @include media-breakpoint-up(md) {
      display: none;
    }

    &::before,
    &::after {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: map-get($color-scheme-list, secondary);
      content: '';
    }

    &::before {
      top: -6px;
    }

    &::after {
      bottom: -6px;
    }
  }

  .saps-menu-toggle__icon {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    .saps-menu-toggle__icon-path {
      fill: map-get($color-scheme-list, secondary);
      transition: all $transition-time;
    }

    &:hover {
      .saps-menu-toggle__icon-path {
        fill: map-get($color-scheme-list, text);
      }
    }
  }
}

.saps-top-menu {
  position: relative;
  width: auto;
  display: none;
  margin: 0;

  @include media-breakpoint-up(xl) {
    display: flex;
  }

  .saps-top-menu__item {
    display: block;
    text-align: center;
    list-style-type: none;
    flex: 0 0 auto;

    .saps-top-menu__item-a {
      display: block;
      font-size: 13px;
      font-weight: bold;
      color: map-get($color-scheme-list, black);
      letter-spacing: 1.2px;
      text-transform: uppercase;
      text-decoration: none;
      padding: 20px 10px;
      transition: all $transition-time;

      @media screen and (min-width: 1400px) {
        padding: 20px;
      }

      &:focus,
      &:active {
        color: map-get($color-scheme-list, secondary);
      }

      @media (hover: hover) {
        &:hover {
          color: map-get($color-scheme-list, secondary);
        }
      }
    }

    &.saps-top-menu__item--active {
      .saps-menu__item-a {
        color: map-get($color-scheme-list, primary);
      }
    }
  }
}

.saps-menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transform-origin: right top;
  transform: scale(0);
  transition: all $transition-time;
  z-index: 11;

  @include media-breakpoint-up(md) {
    transform-origin: center top;
    top: map-get($header-height, mobile);
    z-index: 11;
  }

  @include media-breakpoint-up(lg) {
    top: map-get($header-height, desktop);
  }

  .saps-menu-close {
    @extend %saps-close-cross;

    &:hover {
      &::before,
      &::after {
        background-color: map-get($color-scheme-list, secondary);
      }
    }
  }

  .saps-menu-container {
    position: relative;
    background-color: map-get($color-scheme-list, text);
    max-width: none;
    padding: 55px 15px;
    max-height: 100vh;
    overflow: auto;

    @include media-breakpoint-up(md) {
      box-shadow: 0 8px 12px 12px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      padding: 55px 55px 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 55px 85px 0;
      @include make-container-max-widths();
    }

    @include media-breakpoint-up(xl) {
      padding-left: 165px;
      padding-right: 165px;
    }
  }

  .saps-menu-row {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: solid #ffffff 1px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 0;
      display: flex;
      padding-bottom: 30px;
      margin-bottom: 0;
      border-bottom-color: map-get($color-scheme-list, grey-300);
    }

    .saps-menu-col {
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        flex: 0 0 25%;
        margin-right: 40px;
      }

      .saps-menu-col__title {
        font-size: 20px;
        font-weight: bold;
        color: map-get($color-scheme-list, grey-400);
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          font-size: 15px;
          line-height: 24px;
          color: map-get($color-scheme-list, secondary);
          margin-bottom: 26px;
        }
      }

      .saps-menu-col__list {
        margin: 0;
      }

      .saps-menu-col__list-item {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(md) {
          display: block;
        }

        .saps-menu-col__list-item-icon {
          display: block;
          flex: 0 0 25px;
          margin-right: 20px;

          @include media-breakpoint-up(md) {
            display: none;
          }
        }

        .saps-menu-col__list-item-a {
          display: block;
          text-decoration: none;
          color: #ffffff;
          padding: 5px 0;

          &:focus,
          &:active {
            text-decoration: underline;
          }

          @media (hover: hover) {
            &:hover {
              text-decoration: underline;
            }
          }

          @include media-breakpoint-up(md) {
            color: map-get($color-scheme-list, text);
          }
        }
      }
    }
  }

  .saps-menu-bottom__list {
    margin-bottom: 30px;
    padding: 0 10px;

    @include media-breakpoint-up(md) {
      display: flex;
      padding: 15px 0;
      margin-bottom: 0;
    }

    .saps-menu-bottom__list-item {
      list-style-type: none;
      margin-right: 20px;

      .saps-menu-bottom__list-item-a {
        display: block;
        font-size: 15px;
        line-height: 30px;
        text-decoration: none;
        color: #ffffff;
        padding: 5px 0;

        @include media-breakpoint-up(md) {
          font-size: 13px;
          color: map-get($color-scheme-list, text);
          padding: 0;
        }

        &:focus,
        &:active {
          text-decoration: underline;
        }

        @media (hover: hover) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.saps-menu--active {
    transform: scale(1);
  }
}
