.saps-brands {
  .saps-brands-row {
    justify-content: flex-start;
  }

  .saps-brands-item {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 5px 20px 5px 20px;

    @include media-breakpoint-down(sm) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
