.saps-dealer-items-wrapper {
  position: relative;
  min-height: 120px;
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-up(md) {
    padding-left: 80px;
    padding-right: 80px;
  }

  .saps-dealer-items__arrow {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 10px;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 2;
    outline: none;

    @include media-breakpoint-up(md) {
      width: 10px;
    }

    > * {
      display: block;
    }

    &.saps-dealer-items__arrow--next {
      left: 0;

      > * {
        transform: rotate(180deg);
      }
    }

    &.saps-dealer-items__arrow--prev {
      right: 0;
    }
  }

  .saps-mother-dealer-items__arrow {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 15px;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 2;
    outline: none;

    @include media-breakpoint-up(md) {
      width: 15px;
    }

    > * {
      display: block;
    }

    &.saps-dealer-items__arrow--next {
      right: 0;

      > * {
        transform: rotate(180deg);
      }
    }

    &.saps-dealer-items__arrow--prev {
      left: 0;
    }
  }
}

.saps-dealer-item-box__title-a {
  cursor: pointer;
  text-decoration: none;
  transition: all $transition-time;

  &:hover {
    color: map-get($color-scheme-list, secondary);
  }
}

.saps-dealer-item-box__address {
  line-height: 1.47;
}

.saps-dealer-input {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: map-get($color-scheme-list, white);
}

.saps-dealer-item-box {
  display: flex;

  .saps-dealer-item-box__prefix {
    margin-top: 2px;
    flex: 0 0 auto;
  }

  .saps-dealer-item-box__content {
    flex: 1 1 auto;
  }
}

.saps-dealer-map-search-suggestions {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 2;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: map-get($color-scheme-list, white);

  .saps-dealer-map-search-suggestions__list-wrapper {
    padding: 15px 20px;

    .saps-dealer-map-search-suggestions__list {
      .saps-dealer-map-search-suggestions__li {
        display: block;

        .saps-dealer-map-search-suggestions__a {
          display: block;
          cursor: pointer;
          transition: all $transition-time;

          &:hover {
            color: map-get($color-scheme-list, secondary);
          }
        }
      }
    }
  }
}
