.saps-car-list__filter-mother-card,
.saps-car-list__filter-complete-offer {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  @include media-breakpoint-up(lg) {
    padding: 30px 40px;
  }
}

.saps-car-list__filter-complete-offer {
  justify-content: center;
  margin-bottom: 30px;
}

.saps-car-list__filter-mother-card {
  .saps-mother-card__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .saps-mother-card__count {
    font-size: 20px;
    line-height: 0.93;
    color: #707070;
    margin-bottom: 60px;
  }

  .saps-mother-card__title {
    font-size: 30px;
    font-weight: bold;
    line-height: 0.93;
    color: #000000;
    margin-bottom: 20px;
    margin-top: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 45px;
    }
  }

  .saps-mother-card__item {
    width: 100%;
  }

  .saps-mother-card__row {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .saps-mother-card__contacts {
      margin-top: 20px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        padding-left: 45px;
      }
    }
  }

  .saps-mother-card__carousel-wrapper {
    display: flex;
    margin-top: 0;
  }

  .saps-mother-card__carousel {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 75%;
    }

    .swiper-slide {
      width: 100%;

      @include media-breakpoint-up(xl) {
        width: 33%;
      }
    }
  }

  .saps-mother-card__carousel-one {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 200px;
    }

    .swiper-slide {
      width: 100%;

      @include media-breakpoint-up(xl) {
        width: 33%;
      }
    }
  }

  .saps-mother-card__carousel-hidden {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }

  .saps-desc-title {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
  }

  .saps-mother-card__seller-info {
    font-size: 15px;
    line-height: 18px;
    display: flex;
  }

  .saps-mother-card__seller-info-content {
    margin: 0 auto;
  }
  .saps-mother-card__seller-name {
    max-width: 200px;
    height: 72px;
    display: flex;
    align-items: flex-end;
  }
  /* Arrows */
  .saps-mother-card__carousel-next,
  .saps-mother-card__carousel-prev {
    position: absolute;
    top: 50%;
    width: 15px;
    height: 30px;
    transform: translateY(-50%);
    z-index: 3;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-image: none;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }

  .saps-mother-card__carousel-next {
    right: 0;
    transform: rotate(180deg);
    transform-origin: 50% 25%;
  }

  .saps-mother-card__carousel-prev {
    left: 0;
  }

  .saps-mother-card__carousel .swiper-container {
    @include media-breakpoint-up(lg) {
      &:before,
      &:after {
        content: '';
        height: 100%;
        width: 120px;
        position: absolute;
        top: 0;
        z-index: 2;
      }
    }
  }

  .saps-mother-card__carousel-prev,
  .saps-mother-card__carousel-next {
    transition: linear 0.2s opacity;
  }

  .saps-mother-card__carousel-prev.swiper-button-disabled,
  .saps-mother-card__carousel-next.swiper-button-disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.saps-dealer-card-about__wrapper {
  display: flex;
  flex-direction: row;
}

.saps-dealer-card-sellers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.saps-dealer-card-info__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media-breakpoint-up(xl) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.saps-dealer-card-contact-wrapper {
  display: flex;
}

.saps-dealer-card-info__col {
  width: 100%;
  @include media-breakpoint-up(xl) {
    width: 50%;
  }
}

.saps-dealer-card-image {
  margin: auto;
  width: 88vw;

  @include media-breakpoint-up(md) {
    width: 40vw;
  }

  @include media-breakpoint-up(lg) {
    width: 60vw;
  }
  @include media-breakpoint-up(xl) {
    width: 35vw;
    max-width: 550px;
  }
}

.saps-dealer-card-contact-center {
  text-align: center;
}

.saps-dealer-card-contact-title {
  display: flex;
  flex-direction: row;
}

.saps-dealer-card-contact-icon {
  width: 25px;
  margin-bottom: 25px;
  margin-left: 20px;
  cursor: pointer;
}

.saps-dealer-card-contact-icon__rotated {
  width: 25px;
  margin-bottom: 25px;
  margin-left: 20px;
  transform: rotate(180deg);
  cursor: pointer;
}

.saps-mother-card__sellers-carousel {
  width: 100%;
}

.saps-mother-item-box {
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.saps-dealer-card__container {
  width: 100%;
}

.saps-mother-dealer-item {
  width: 260px;
  padding: 0 30px;
}
