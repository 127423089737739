.saps-tab-list {
  display: flex;

  //Touch devices scroll tables
  @include media-breakpoint-down(lg) {
    overflow-x: auto;
  }
}

.saps-tab-title {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding: 9px 0;
  color: map-get($color-scheme-list, grey-200);
  list-style: none;
  margin-right: 43px;
  cursor: pointer;
  user-select: none;
  border-bottom: solid transparent 2px;
  white-space: nowrap;
  transition: all $transition-time;

  @include media-breakpoint-up(md) {
    padding: 5px 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: map-get($color-scheme-list, text);
  }

  &.saps-tab-title--active {
    color: map-get($color-scheme-list, text);
    border-color: map-get($color-scheme-list, text);

    &.saps-tab-title--inner-link {
      .saps-tab-title__a {
        color: map-get($color-scheme-list, text);
        border-color: map-get($color-scheme-list, text);
      }
    }
  }

  .saps-tab-title__a {
    display: block;
    color: inherit;
    text-decoration: none;
  }

  &.saps-tab-title--inner-link {
    padding: 0;
    border: none;

    .saps-tab-title__a {
      padding: 9px 0;
      color: map-get($color-scheme-list, grey-200);
      text-decoration: none;
      border-bottom: solid transparent 2px;
      transition: all $transition-time;

      @include media-breakpoint-up(md) {
        padding: 5px 0;
      }

      &:hover {
        color: map-get($color-scheme-list, text);
      }
    }
  }
}

.saps-icontab-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    margin-bottom: 25px;
    margin-left: -25px;
    margin-right: -25px;
  }
}

.saps-icontab-list {
  @include media-breakpoint-down(md) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}

.saps-icontab-title {
  position: relative;
  list-style: none;
  text-align: center;
  padding-bottom: 40px;
  opacity: 0.5;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  user-select: none;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(md) {
    width: auto;
    height: auto;
    border: none;
    margin-left: 0;
    margin-right: 0;
    min-width: 60px;
    opacity: 1;
    padding-bottom: 20px;

    &:hover {
      .saps-basicfilter-purpose-tab-label {
        color: map-get($color-scheme-list, secondary);
      }

      [class*='saps-tab-icon-artwork'] {
        fill: map-get($color-scheme-list, secondary);
      }
    }
  }
}

.saps-basicfilter-purpose-checkmark {
  display: none;
  position: absolute;
  top: 0;
  right: -20px;
}

.saps-icontab-title--active {
  opacity: 1;

  .saps-basicfilter-purpose-tab-label {
    font-weight: bold;
  }

  .saps-basicfilter-purpose-checkmark {
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

.saps-icontab-title__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  border: solid map-get($color-scheme-list, text) 2px;
  border-radius: 50%;

  @include media-breakpoint-up(md) {
    width: auto;
    height: 60px;
    border: none;
    max-width: 60px;
  }
}

[class*='saps-tab-icon-artwork'] {
  fill: map-get($color-scheme-list, text);
}

[class*='saps-tab-icon'] {
  display: block;
}

.saps-tab-content {
  display: none;

  &.saps-tab-content--active {
    display: block;
  }
}

.baby_svg__saps-tab-icon {
  width: 30px;

  @include media-breakpoint-down(sm) {
    width: 66px;
  }
}

.travel_svg__saps-tab-icon {
  width: 30px;

  @include media-breakpoint-down(sm) {
    width: 52px;
  }
}

.work_svg__saps-tab-icon {
  width: 30px;

  @include media-breakpoint-down(sm) {
    width: 52px;
  }
}

.transportation_svg__saps-tab-icon {
  width: 30px;

  @include media-breakpoint-down(sm) {
    width: 52px;
  }
}

.fun_svg__saps-tab-icon {
  width: 30px;

  @include media-breakpoint-down(sm) {
    width: 52px;
  }
}
