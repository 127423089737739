* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  font-family: $font-family;
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
}

body {
  font-weight: 300;
  font-size: 15px;
  color: map-get($color-scheme-list, text);
  background-color: #f7f7f7;
  line-height: 1.6;
  text-align: left;
  padding-top: map-get($header-height, mobile);
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    padding-top: map-get($header-height, desktop);
  }
}

button:focus {
  outline: none;
}

.saps-body-has-bgr {
  background-image: $body-bgr-image;
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: right top;
}

.saps-close-mobile-dialog {
  position: fixed;
  top: 0;
  right: 0;
  padding: 24px;
  z-index: 7;
  cursor: pointer;

  .saps-close-mobile-dialog__icon {
    display: block;
    width: 17px;
    height: auto;
  }

  .saps-close-mobile-dialog__path {
    stroke: map-get($color-scheme-list, secondary);
    stroke-width: 4px;
    transition: all $transition-time;
  }
}

.saps-dl {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.saps-hovercraft {
  box-shadow: 0 10px 30px 0 map-get($color-scheme-list, grey-300);
}

.saps-list-p {
  .saps-list-p__title {
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
  }

  .saps-list-p__li {
    font-size: 15px;
    line-height: 1.6;
    display: block;
  }
}

.saps-checklist {
  .saps-checklist__li {
    position: relative;
    display: block;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.33;
    padding-left: 40px;
    margin-bottom: 15px;

    &::before {
      position: absolute;
      top: 5px;
      left: 0;
      width: 18px;
      height: 18px;
      // background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 382"%3e%3cpath d="M173.9 374.3L7.5 207.9c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 247.6 432.1 7.5c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2L210.1 374.3c-10 10-26.2 10-36.2 0z" fill="%23ef7d00"/%3e%3c/svg%3e');
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 382'%3E%3Cpath d='M173.9 374.3L7.5 207.9c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 247.6 432.1 7.5c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2L210.1 374.3c-10 10-26.2 10-36.2 0z' fill='%23ef7d00'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 18px 14px;
      content: '';
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.saps-checklist--dual-box-polygonal {
    .saps-checklist__li {
      line-height: 1.07;
      font-weight: bold;
      padding-left: 0;

      &::before {
        top: 2px;
        left: -28px;
        //background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 382"%3e%3cpath d="M173.9 374.3L7.5 207.9c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 247.6 432.1 7.5c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2L210.1 374.3c-10 10-26.2 10-36.2 0z" fill="%23ffffff"/%3e%3c/svg%3e');
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 382'%3E%3Cpath d='M173.9 374.3L7.5 207.9c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 247.6 432.1 7.5c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2L210.1 374.3c-10 10-26.2 10-36.2 0z' fill='%23ef7d00'/%3E%3C/svg%3E");
        background-size: 16px 12px;
      }
    }
  }

  &.saps-checklist--primary {
    .saps-checklist__li {
      &::before {
        //background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 382"%3e%3cpath d="M173.9 374.3L7.5 207.9c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 247.6 432.1 7.5c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2L210.1 374.3c-10 10-26.2 10-36.2 0z" fill="%23009c00"/%3e%3c/svg%3e');
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 382'%3E%3Cpath d='M173.9 374.3L7.5 207.9c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 247.6 432.1 7.5c10-10 26.2-10 36.2 0l36.2 36.2c10 10 10 26.2 0 36.2L210.1 374.3c-10 10-26.2 10-36.2 0z' fill='%23ef7d00'/%3E%3C/svg%3E");
      }
    }
  }
}

.saps-checklist-banner {
  .saps-checklist-banner__li {
    position: relative;
    display: block;
    font-size: 27px;
    font-weight: normal;
    line-height: 1.33;
    padding-left: 40px;
    color: white;

    &::before {
      position: absolute;
      top: 16px;
      left: 0;
      width: 20px;
      height: 20px;
      background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" width="617.125" height="617.15625" id="svg1329"%3e %3cg transform="translate(-71.4375,-68.0625)"%3e %3cpath style="fill:%23ef7d00" d="M 305.71875,68.0625 L 305.71875,302.375 L 71.4375,302.375 L 71.4375,450.9375 L 305.71875,450.9375 L 305.71875,685.21875 L 454.28125,685.21875 L 454.28125,450.9375 L 688.5625,450.9375 L 688.5625,302.375 L 454.28125,302.375 L 454.28125,68.0625 L 305.71875,68.0625 z"/%3e %3c/g%3e %3c/svg%3e ');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      content: '';
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bottom-banner-text-left__secondary {
  display: flex;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.bottom-banner-checklist {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.bottom-banner-title-right {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
}

.bottom-banner-wrapper {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.saps-equipment-pipe {
  color: map-get($color-scheme-list, grey-100);
}

.saps-list-with-icons {
  .saps-list-with-icons__li {
    display: block;
    position: relative;
    padding-left: 43px;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.4;
    margin-bottom: 9px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  [class*='saps-list-with-icons__icon'],
  .saps-list-with-icons__icon {
    position: absolute;
    left: -0;
    top: -5px;
    width: 30px;
    height: auto;
  }
}

.saps-opening-hours-list {
  padding-bottom: 20px;
  .saps-opening-hours-list__row {
    display: flex;
    list-style-type: none;
    flex-wrap: nowrap;

    .saps-opening-hours__label {
      display: block;
      flex: 0 0 80px;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: map-get($color-scheme-list, grey-300);
    }

    .saps-opening-hours__value {
      display: block;
      flex: 1 0 auto;
      font-size: 15px;
      font-weight: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: map-get($color-scheme-list, text);
    }
  }
}

.saps-list-social-bubbles {
  display: flex;
}

.saps-list-social-bubbles__li {
  list-style-type: none;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.saps-list-social-bubbles__a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: map-get($color-scheme-list, secondary);
  transition: all $transition-time;
  cursor: pointer;

  &:hover {
    background-color: map-get($color-scheme-list, secondary-hover);
  }
}

.cross_svg__saps-close__path {
  stroke: #ef7d00;
  stroke-width: 4px;
  transition: all 150ms;

  .saps-cars-comparison-car__image-wrapper & {
    @include media-breakpoint-down(sm) {
      stroke: #fff;
    }
  }
}

.cebia-star__icon {
  .saps-cars-comparison-car__cebia & {
    @include media-breakpoint-down(sm) {
      width: 15px;
    }
  }
}

.cebia-star__icon-fill {
  margin-right: 3px;
  margin-left: 3px;

  .saps-cars-comparison-car__cebia & {
    fill: map-get($color-scheme-list, 'grey-500');
  }
}

.saps-toolbar {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 21;
  background: #fff;
  width: calc(100% - 340px);
  max-height: 150px;
  max-width: 1100px;
}

.saps-carret-right-icon {
  text-decoration: none;
  position: relative;
  top: 3px;
}

.saps-table {
  border-spacing: 0;
  border-collapse: collapse;

  .saps-tr {
    height: 58px;

    .saps-th,
    .saps-td {
      vertical-align: middle;
      padding: 9px 25px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    .saps-td {
      border: none;
      border-top: solid map-get($color-scheme-list, grey-300) 1px;

      &.saps-td--no-border {
        border: none;
      }
    }
  }
}

.saps-columns {
  columns: 1;

  * {
    break-inside: avoid;
  }

  @include media-breakpoint-up(md) {
    columns: 2;
  }

  @include media-breakpoint-up(lg) {
    columns: 3;
  }
}

.saps-a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: inherit;
  }
}

.saps-break-word-anywhere {
  word-wrap: anywhere;
}

.saps-process-num {
  font-size: 50px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: map-get($color-scheme-list, orange) transparent map-get($color-scheme-list, orange)
    transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.saps-file-to-upload-list {
  .saps-file-to-upload-list__li {
    display: block;
    font-size: 13px;
  }
}

[data-tip] {
  user-select: none;
}

.saps-bg-white {
  background-color: map-get($color-scheme-list, white);
}

.dropdown_svg__saps-dropdown-icon {
  width: 13px;

  @include media-breakpoint-down(sm) {
    width: 18px;
  }
}
