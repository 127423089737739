$saps-dot-size: 5px !default;
$saps-dot-size-active: 15px !default;
$saps-dot-color: map-get($color-scheme-list, grey-400) !default;
$saps-dot-color-active: map-get($color-scheme-list, grey-500) !default;
$saps-opacity-on-hover: 0.8 !default;
$saps-opacity-default: 1 !default;

/* Arrows */
.saps-carousel-next,
.saps-carousel-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 110px;
  background-color: map-get($color-scheme-list, grey-500);
  z-index: 3;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-image: none;
  cursor: pointer;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  @include media-breakpoint-down(md) {
    display: none !important;
  }

  &.saps-carousel--force-display-arrows {
    @include media-breakpoint-down(md) {
      display: block !important;
    }
  }

  .saps-cars-comparison & {
    top: 260px;
    opacity: 0.5;

    &:hover {
      opacity: 0.8;
    }
  }
}

.saps-carousel-next {
  right: 0;
  transform: rotate(180deg);
  transform-origin: 50% 25%;

  .saps-cars-comparison & {
    position: fixed;
    right: calc((100% - 1443px) / 2);

    @media (max-width: 1443px) {
      right: 0;
    }
  }
}

.saps-carousel-prev {
  left: 0;

  .saps-cars-comparison & {
    position: fixed;
    left: calc(((100% - 1443px) / 2) + 380px);

    @media (max-width: 1443px) {
      left: 380px;
    }
  }
}

.saps-cars-carousel__wrapper .swiper-container {
  @include media-breakpoint-up(lg) {
    &:before,
    &:after {
      content: '';
      height: 100%;
      width: 120px;
      position: absolute;
      top: 0;
      z-index: 2;
    }
  }
}

.saps-cars-carousel__wrapper > .swiper-container {
  @include media-breakpoint-up(lg) {
    &:before {
      left: 0;
      background: linear-gradient(to right, rgb(247, 247, 247) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:after {
      right: 0;
      background: linear-gradient(to left, rgb(247, 247, 247) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
}

/* Dots */
.saps-cars-carousel__wrapper .swiper-pagination {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 0;
  position: static;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  .swiper-pagination-bullet {
    list-style: none;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
    line-height: 0;
    width: $saps-dot-size-active;
    height: $saps-dot-size-active;
    cursor: pointer;
    background-color: transparent;

    &:hover,
    &:focus {
      outline: none;
      opacity: $saps-opacity-on-hover;
    }

    &:active,
    &:focus {
      opacity: $saps-opacity-default;
    }

    &:before {
      content: '';
      width: $saps-dot-size;
      height: $saps-dot-size;
      border-radius: 100%;
      background-color: $saps-dot-color;
      transition: all 0.2s ease-in-out;
    }

    &.swiper-pagination-bullet-active {
      &:before {
        width: $saps-dot-size-active;
        height: $saps-dot-size-active;
        background-color: $saps-dot-color-active;
      }
    }
  }
}

.saps-cars-carousel__arrow-icon {
  fill: #fff;
}

.saps-carousel-prev,
.saps-carousel-next {
  transition: linear 0.2s opacity;
}

.saps-carousel-prev.swiper-button-disabled,
.saps-carousel-next.swiper-button-disabled {
  opacity: 0.5;
  cursor: default;
}
