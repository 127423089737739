.saps-lightbox-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  transform: translateY(-120vh);
  transform-origin: center top;
  transition: all $transition-time;

  &.saps-lightbox-wrapper--active {
    transform: translateY(0);
  }

  .saps-lightbox {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1240px;
    background-color: #f5f6f5;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      max-height: 90vh;
    }

    &.saps-lightbox--no-image-small {
      max-width: 480px;
    }

    &.saps-lightbox--no-image-large {
      max-width: 640px;
    }

    &.saps-lighbox--watchdog {
      max-width: 920px;
      max-height: 100%;
      overflow: auto;
      background-color: map-get($color-scheme-list, white);
      padding: 30px;

      @include media-breakpoint-up(md) {
        padding: 40px 60px;
      }

      @include media-breakpoint-up(lg) {
        padding: 80px 120px;
      }

      .saps-lightbox__content-wrapper {
        &::before {
          display: none;
        }
      }
    }

    .saps-lightbox__close {
      @extend %saps-close-cross-large;
    }

    .saps-lightbox__image {
      position: relative;
      flex: 1 0 100%;
      height: 40vh;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      //For video Icon
      justify-content: center;
      align-items: center;
      display: flex;

      @include media-breakpoint-up(md) {
        flex: 0 0 52%;
        height: unset;
      }
    }

    .saps-lightbox__content-wrapper {
      position: relative;
      //flex: 1 0 0;
      flex: 1 1 auto;
      max-height: 100vh;
      z-index: 2;

      //@include media-breakpoint-up(md) {
      //    flex: 1 0 0;
      //}

      &::before {
        position: absolute;
        background-color: #f5f6f5;
        content: '';
        top: -30px;
        left: -10%;
        width: 120%;
        height: 80px;
        transform: rotate(-3deg);

        @include media-breakpoint-up(md) {
          top: -10%;
          left: -15px;
          width: 30px;
          height: 120%;
          z-index: 1;
        }
      }
    }

    .saps-lightbox__content {
      position: relative;
      overflow: auto;
      padding: 40px;
      z-index: 1;

      @include media-breakpoint-up(md) {
        padding: 20% 80px;
      }
    }

    .saps-lightbox__category {
      opacity: 0.5;
      font-size: 13px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: #aaaaaa;
      margin-bottom: 34px;
    }

    &.saps-outer-close {
      overflow: visible;
      max-width: 80vw;

      .saps-lightbox__close {
        right: -65px;
        top: -65px;
      }
    }
  }
}

.saps-outer-close .saps-lightbox__content-wrapper::before {
  display: none;
}

//.saps-lightbox-carousel {
//  margin: 0 auto;
//  position: relative;
//  overflow: hidden;
//  padding: 0;
//  z-index: 1;
//  flex: 0 0 52%;
//}

.saps-lightbox-carousel {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0;
  z-index: 1;
  flex: 1 0 52%;
  min-height: 400px;
}

.saps-lightbox-carousel__slide {
  flex: 0 0 100%;
  background-size: cover;
  background-position: center;

  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saps-lightbox-carousel_video-button {
  cursor: pointer;
  opacity: 0.8;
}

.saps-lightbox-carousel_video-button:hover {
  opacity: 1;
}

.saps-lightbox-carousel__pagination {
  position: absolute;
  bottom: 0;
  z-index: 1;
  text-align: center;
  margin-bottom: 20px;
}

.saps-lightbox-carousel__pagination-bullet {
  border: 1px solid #fff;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.saps-lightbox-carousel__pagination-bullet_active {
  background: #fff;
}

.polygon {
  z-index: 0;
  height: 100%;
  width: 28px;
  position: absolute;
  bottom: 0;
  right: 100%;
}
.polygon svg {
  width: 100%;
  height: 100%;
}
.polygon svg polygon {
  fill: #f5f6f5;
}
