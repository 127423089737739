.saps-h1 {
  font-size: 35px;
  font-weight: 300;
  line-height: 1.14;
  letter-spacing: -0.1px;

  @include media-breakpoint-up(md) {
    font-size: 60px;
    line-height: 0.98;
    letter-spacing: -0.2px;
  }
}

.saps-h2 {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.09;

  @include media-breakpoint-up(md) {
    font-size: 45px;
    line-height: 0.93;
  }
}

.saps-h3 {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.23;

  @include media-breakpoint-up(md) {
    font-size: 30px;
    line-height: 1.13;
  }
}

.saps-h4 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.23;

  @include media-breakpoint-up(md) {
    font-size: 20px;
    line-height: 1.3;
  }
}

.saps-h5 {
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
}

.saps-p {
  font-size: 15px;
  line-height: 1.6;
}

.saps-p-small {
  font-size: 13px;
  letter-spacing: 0.2px;
}
