.saps-page-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.saps-page-content {
  min-height: 400px;
  position: relative;
}

.saps-claim-box {
  position: relative;
  background-size: cover;
  background-position: left center;

  * {
    position: relative;
    z-index: 1;
  }

  &::after {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 160px;
    width: 100%;
    background-image: linear-gradient(0deg, #000000, rgba(0, 0, 0, 0));
    content: '';
    z-index: 0;
  }

  @include media-breakpoint-up(md) {
    &::after {
      display: none;
    }
  }

  &.saps-claim-box--top-banner {
    &::after {
      background-image: linear-gradient(0deg, #202020, rgba(32, 32, 32, 0));
    }
  }
}

.saps-hp-bottom-boxes__title {
  color: #ffffff;

  @include media-breakpoint-up(md) {
    color: #000000;
  }
}

.saps-slick-mobile-with-arrows {
  position: relative;
  padding-left: 50px;
  padding-right: 50px;

  @include media-breakpoint-up(md) {
    padding-left: 0;
    padding-right: 0;
  }

  .saps-slick-mobile-with-arrows__arrow {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    padding: 20px 15px;
    cursor: pointer;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }

    &.saps-slick-mobile-with-arrows__arrow--next-to-icon {
      top: 60px;
      margin-top: 0;
      padding: 10px 15px 30px;
    }
  }

  .slick-dots {
    @extend %saps-slider-dots;

    li {
      @extend %saps-slider-dot;

      &::after {
        @extend %saps-slider-dot-after;
      }

      &.slick-active {
        &::after {
          @extend %saps-slider-dot-active-after;
        }
      }

      &:hover {
        &::after {
          @extend %saps-slider-dot-hover-after;
        }
      }

      button {
        display: none;
      }
    }
  }

  .saps-slick-mobile-with-arrows__dost-placeholder {
    height: 25px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .saps-slick-mobile-with-arrows__dots--under-icon {
    position: absolute;
    top: 135px;
    left: 0;
    width: 100%;
  }
}

.saps-hp-banner-wrapper {
  background-position: right bottom;
  background-image: url(/documents/891784/891880/skoda-z-pohodli-domova_desktop.jpg);

  @include media-breakpoint-only(xs) {
    background-repeat: no-repeat;
    background-image: url(/documents/891784/891880/skoda-z-pohodli-domova_mobil.jpg);
    background-color: #151515;
  }

  @include media-breakpoint-up(md) {
    padding-left: 0;
    padding-right: 40px;
  }

  @include media-breakpoint-up(xl) {
    padding-left: 160px;
    padding-right: 160px;
  }
}

.saps-has-hover-content {
  position: relative;

  .saps-hover-content {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all $transition-time;
    z-index: 1;

    &.saps-hover-content--visible-on-mobile {
      position: relative;
      opacity: 1;

      @include media-breakpoint-up(md) {
        position: absolute;
        opacity: 0;
      }
    }
  }

  &:hover {
    .saps-hover-content {
      opacity: 1;
    }
  }
}

.saps-calc-financing-parent {
  display: none;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 10;
  background-color: map-get($color-scheme-list, white);

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.saps-calc-financing-parent-wrapper {
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 744px;
  height: auto;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 760 530"%3e%3cpath d="M104 20L20 511h720V20z" fill="%23f1f1f1" filter="drop-shadow(0 0 7px rgba(195, 195, 195, 0.5))"/%3e%3c/svg%3e');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  padding: 57px 81px 70px 143px;
  overflow: hidden;
  transform: scale(0);
  transform-origin: right bottom;
  transition: all $transition-time;

  &.saps-calc-financing-parent-wrapper--active {
    transform: scale(1);
  }

  .saps-fin-calc-close {
    display: block;
    position: absolute;
    right: -35px;
    top: -10px;
    cursor: pointer;

    .saps-fin-calc-close__times {
      position: relative;
      display: block;
      width: 30px;
      height: 30px;

      &::before,
      &::after {
        display: block;
        position: absolute;
        left: -5px;
        top: 14px;
        width: 41px;
        height: 1px;
        background-color: map-get($color-scheme-list, grey-200);
        content: '';
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}

.saps-dual-box-polygonal {
  color: map-get($color-scheme-list, white);
  min-height: 240px;
  overflow: hidden;

  .saps-dual-box-polygonal__title {
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
  }

  .saps-dual-box-polygonal__subtitle {
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: normal;
    opacity: 0.7;
  }

  .saps-dual-box-polygonal__left,
  .saps-dual-box-polygonal__right {
    position: relative;
    height: 100%;
    padding: 35px 55px;

    @include media-breakpoint-up(md) {
      padding: 35px 10px;
    }
  }

  .saps-dual-box-polygonal__left {
    background-color: map-get($color-scheme-list, secondary);
    z-index: 1;

    @include media-breakpoint-up(md) {
      margin-left: 80px;

      &::before {
        display: block;
        position: absolute;
        left: -55px;
        width: 80px;
        height: 120%;
        top: -10%;
        background-color: map-get($color-scheme-list, secondary);
        transform: rotate(-9deg);
        content: '';
        z-index: -1;
      }

      &::after {
        display: block;
        position: absolute;
        right: -20px;
        width: 50px;
        height: 120%;
        top: -10%;
        background-color: map-get($color-scheme-list, secondary);
        transform: rotate(9deg);
        content: '';
      }
    }
  }

  .saps-dual-box-polygonal__right {
    background-color: map-get($color-scheme-list, grey-300);
    z-index: 0;

    @include media-breakpoint-up(md) {
      padding-left: 80px;
      margin-right: 40px;

      &::after {
        display: block;
        position: absolute;
        right: -20px;
        width: 50px;
        height: 120%;
        top: -10%;
        background-color: map-get($color-scheme-list, grey-300);
        transform: rotate(9deg);
        content: '';
        z-index: -1;
      }
    }
  }
}

.saps-react-swiper {
  .swiper-pagination-bullets {
    @extend %saps-slider-dots;

    .swiper-pagination-bullet {
      @extend %saps-slider-dot;

      &::after {
        @extend %saps-slider-dot-after;
      }

      &:hover {
        &::after {
          @extend %saps-slider-dot-hover-after;
        }
      }

      &.swiper-pagination-bullet-active {
        &::after {
          @extend %saps-slider-dot-active-after;
        }
      }
    }
  }
}

.saps-sell-car-map-wrapper {
  max-width: 438px;
}

.saps-live-chat-toggle {
  position: fixed;
  display: flex;
  align-items: center;
  top: 50%;
  left: 100%;
  height: 32px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  white-space: nowrap;
  color: map-get($color-scheme-list, secondary);
  border: none;
  border-radius: 8px 8px 0 0;
  background-color: map-get($color-scheme-list, white);
  box-shadow: 0 -4px 7px 0 rgba(74, 74, 74, 0.2);
  transform: rotate(-90deg);
  transform-origin: left top;
  cursor: pointer;
  margin-left: -32px;
  padding: 0 10px;
  z-index: 10;
  @include media-breakpoint-down(md) {
    display: none;
  }

  .saps-live-chat-toggle__icon {
    display: block;
    width: 16px;
    margin-left: 10px;
  }

  .saps-live-chat-toggle__icon-path {
    fill: map-get($color-scheme-list, secondary);
  }
}

.saps-live-chat-window {
  position: fixed;
  top: -150vh;
  width: 789px;
  height: 500px;
  z-index: -1;
  opacity: 0;

  &.saps-live-chat-window--active {
    position: fixed;
    top: 10vh;
    left: 50%;
    background: white;
    z-index: 15;
    width: 789px;
    height: 85vh;
    margin-left: -395px;
    box-shadow: 12px 14px 62px 6px rgba(0, 0, 0, 0.75);
    opacity: 1;
    transition: opacity $transition-time;
    .saps-live-chat-window__iframe {
      width: 100%;
      height: 100%;
    }

    .saps-live-chat-window__close {
      float: right;
      text-align: center;
      margin-top: -30px;
      color: white;
      background: black;
      width: 30px;
      height: 30px;
      font-size: 19px;
      cursor: pointer;
    }
  }
}

.saps-num-in-circle {
  display: block;
  font-size: 10px;
  width: 19px;
  height: 19px;
  text-align: center;
  line-height: 17px;
  border: solid map-get($color-scheme-list, text) 2px;
  border-radius: 50%;
}

.saps-cookies-bar {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  z-index: 50;
  transition: all $transition-time;

  //@include media-breakpoint-up(md) {
  //    bottom: -200px;
  //}

  &.saps-cookies-bar--active {
    display: block;
    bottom: 0;
  }
}

.saps-car-detail-dealer__panel {
  max-height: 0;
  overflow: hidden;
  transition: all $transition-time;

  &--active {
    height: auto;
    max-height: 1200px;
    > *:first-child {
      padding-top: 30px;
    }
    > *:last-child {
      padding-bottom: 30px;
    }
  }

  &--header {
    .saps-inputlist-dropdown__toggle-icon {
      transition: all $transition-time;
    }

    &-active {
      border-bottom: 1px solid map-get($color-scheme-list, grey-300);
      .saps-inputlist-dropdown__toggle-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.saps-cars-list-toolbar {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
  width: 100%;
  max-height: 40px;
  transition: all $transition-time;
  background: #fff;

  @include media-breakpoint-down(sm) {
    position: relative;
  }

  * {
    transition: all $transition-time;
  }

  &__tabs {
    flex: 1 1 80%;
    max-width: 80%;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    .saps-tab-list {
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: map-get($color-scheme-list, grey-300);
        border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: map-get($color-scheme-list, grey-400);
      }
    }

    @include media-breakpoint-down(xs) {
      padding-top: 20px;
      .saps-tab-list {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    width: calc(100vw - 340px);
    max-width: 1100px;
    box-shadow: none;
    right: 0;
    flex: 0 0 calc(100% - 340px);
    left: 340px;
  }

  .saps-inputlist-dropdown__toggle-icon {
    transition: all $transition-time;
  }

  &:not(.saps-cars-list-toolbar--active) {
    .react-tabs__tab-panel {
      display: none;
    }
  }

  &--active {
    max-height: 150px;
    .saps-inputlist-dropdown__toggle-icon {
      transform: rotate(-180deg);
    }
    .saps-cars-list-toolbar__tabs {
      opacity: 1;
      min-height: 150px;
    }
  }

  &--active &__compare-button {
    opacity: 1;
    visibility: visible;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    flex: 0 0 20%;
    max-width: 20%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 10px;
    padding: 5px 10px 20px;
    overflow: visible;
    cursor: pointer;
  }

  &__compare-button {
    max-width: 120px;
    opacity: 0;
    visibility: hidden;

    @include media-breakpoint-down(xs) {
      display: block !important;
      width: 50px;
      height: 50px;
      text-indent: -9999px;
      white-space: nowrap;
      position: relative;
      padding: 0;
      margin: 0;
      border-radius: 100%;
      &:before {
        display: block;
        width: 15px;
        height: 15px;
        content: '';
        border-top: 2px solid white;
        border-right: 2px solid white;
        position: absolute;
        top: 50%;
        left: 44%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}

.saps-toolbar-tab-carousel {
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.saps-toolbar-car {
  position: relative;
  display: flex;
  margin-right: 45px;
  width: 245px;

  @include media-breakpoint-down(xs) {
    width: 120px;
    flex-direction: column;
    min-height: 130px;
    padding-right: 5px;
    padding-left: 5px;
  }
}

.saps-toolbar-car__cross-icon {
  position: absolute;
  top: -10px;
  left: calc(100% - 20px);
  z-index: 22;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
  background-color: map-get($color-scheme-list, orange);
  border-radius: 50%;

  .cross_svg__saps-close__path {
    stroke: map-get($color-scheme-list, white);
  }

  @include media-breakpoint-up(sm) {
    background-color: transparent;
    width: 10px;
    height: 10px;
    padding: 0px;
    .cross_svg__saps-close__path {
      stroke: map-get($color-scheme-list, orange);
    }
  }
}

.saps-toolbar-car__link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.saps-toolbar-car__image {
  height: 80px;
  background-size: cover;
  background-position: 50% 50%;
  @include media-breakpoint-up(sm) {
    width: 107px;
    margin-right: 13px;
  }
}

.saps-toolbar-car__main-info {
  min-width: 120px;

  @include media-breakpoint-down(xs) {
    text-align: center;

    margin-top: 10px;
    > * {
      display: none;
    }
    .saps-toolbar-car__make,
    .saps-toolbar-car__model {
      display: block;
    }
  }
}

.saps-toolbar-car__make,
.saps-toolbar-car__model {
  font-size: 13px;
  font-weight: 600;
  color: map-get($color-scheme-list, grey-500);
  line-height: 1;
}

.saps-toolbar-car__model-level {
  font-size: 13px;
  color: map-get($color-scheme-list, grey-300);
  margin-bottom: 17px;
  line-height: 1;
  margin-top: 5px;
}

.saps-toolbar-car__price-width-vat {
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  color: map-get($color-scheme-list, grey-300);
}

.saps-cars-list-filter__actions {
  flex: 0 0 340px;
}

.saps-car-list-filter__submit {
  width: 100%;
  height: 100px;
  flex-flow: column wrap;
  background-color: map-get($color-scheme-list, secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}

.saps-car-list-filter__submit-text {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
}

.saps-search-suggestions {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 2;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: map-get($color-scheme-list, white);

  .saps-search-suggestions__list-wrapper {
    padding: 15px 20px;

    .saps-search-suggestions__list {
      .saps-search-suggestions__li {
        display: block;

        .saps-search-suggestions__a {
          display: block;
          cursor: pointer;
          transition: all $transition-time;

          &:hover {
            color: map-get($color-scheme-list, secondary);
          }
        }
      }
    }
  }
}

.text-left {
  text-align: left;
}
