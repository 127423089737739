.saps-modal-md {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: block;
  transform: scale(0);
  transform-origin: center top;
  transition: all $transition-time;
  z-index: 12;
}

.saps-modal-md--content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: map-get($color-scheme-list, grey-50);
  width: 100%;
  max-width: 600px;
  height: auto;
  max-height: 80%;
  overflow: hidden;
  display: block;
  padding: 0;
  box-sizing: border-box;
}

.saps-modal-page {
  display: block;
  width: 100%;
  transform: scale(0);
  transform-origin: center top;
  transition: all $transition-time;
  z-index: 12;
  background-color: map-get($color-scheme-list, grey-50);
}

.saps-modal-page_active {
  transform: scale(1);
}

.saps-modal-page__close {
  padding: 25px;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: content-box;
}
