.saps-footer {
  position: relative;
  color: map-get($color-scheme-list, grey-300);
  font-size: 13px;
  z-index: 2;

  .saps-footer-top {
    position: relative;
    left: 0;
    display: block;
    width: 100%;
    overflow: hidden;
    transform: translateY(0.5px);
    -webkit-transform: translateY(0.5px);

    .saps-footer-top__img {
      position: relative;
      display: block;
      width: 104%;
      left: -2%;
    }
  }

  &.saps-footer--has-top-overlay {
    .saps-footer-top {
      position: absolute;
      bottom: 100%;
      transform: translateY(0.5px);
      -webkit-transform: translateY(0.5px);
    }
  }

  .saps-footer-content {
    position: relative;
    background-color: #1e1e1e;
    background-image: url(../images/footer-bgr.svg);
    background-size: 104% auto;
    background-repeat: no-repeat;
    background-position: center top;
    padding-left: 70px;
    border-bottom: solid map-get($color-scheme-list, secondary) 20px;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      border: none;
    }
  }

  .saps-footer-container {
    position: relative;
    padding-top: 70px;
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .saps-footer-title {
    display: none;
    opacity: 0.5;
    font-size: 13px;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .saps-footer-row {
    @include media-breakpoint-up(md) {
      padding-right: 106px;
    }
  }

  .saps-footer-links-list {
    margin: 0;

    .saps-footer-links-list__item {
      display: block;

      .saps-footer-links-list__link {
        display: inline-block;
        color: inherit;
        font-size: 13px;
        line-height: 2.15;
        font-weight: normal;
        opacity: 0.5;
        transition: all $transition-time;
        text-decoration: none;

        &:active,
        &:focus {
          text-decoration: underline;
          opacity: 1;
        }

        @media (hover: hover) {
          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }
  }

  .saps-footer-claim {
    font-size: 13px;
    font-weight: normal;
    opacity: 0.5;
    margin-top: 25px;

    @include media-breakpoint-up(md) {
      margin-top: 70px;
    }
  }

  .saps-footer-sp-bar {
    display: none;
    position: absolute;
    width: 106px;
    top: -19px;
    right: 0;
    bottom: 0;
    background-color: map-get($color-scheme-list, #e7e5e3);
    background-image: linear-gradient(50deg, #919191 0%, #e7e5e3 60%);

    @include media-breakpoint-up(md) {
      display: block;
    }

    .saps-footer-sp-bar-content {
      color: map-get($color-scheme-list, black);
      text-align: center;
      padding: 24px 0 20px 0;
      border-bottom: solid map-get($color-scheme-list, secondary) 3px;
    }
  }
}
