// responsive grid
$grid-gutter-width: 20px;
$grid-gutter-width-md: 40px;

@include media-breakpoint-up(md) {
  .container,
  .container-desktop,
  .container-fluid {
    padding-right: $grid-gutter-width-md / 2;
    padding-left: $grid-gutter-width-md / 2;
  }

  .row {
    margin-right: -$grid-gutter-width-md / 2;
    margin-left: -$grid-gutter-width-md / 2;

    .col,
    [class^='col-'],
    [class^=' col-'] {
      padding-right: $grid-gutter-width-md / 2;
      padding-left: $grid-gutter-width-md / 2;
    }

    &.no-gutters {
      margin-right: 0;
      margin-left: 0;

      .col,
      [class^='col-'],
      [class^=' col-'] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
