.rc-slider {
  border-radius: 0;

  @include media-breakpoint-up(md) {
    &::before {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1px;
      height: 5px;
      background-size: calc(100% - 1px) auto;
      background-image: repeating-linear-gradient(
        90deg,
        #e9e9e9 0,
        #e9e9e9 1px,
        transparent 1px,
        transparent 11.11%
      );
      content: '';
    }
  }

  &-rail {
    border-radius: 0;
  }

  &-handle {
    margin-left: -9px;
    margin-top: -8px;
    width: 20px;
    height: 20px;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);

    &:focus,
    &:active {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
      border: none;
    }
  }

  &:focus {
    border: none;
    outline: none;
    box-shadow: unset;
  }

  &-click-focused:focus {
    border: none;
    box-shadow: unset;
  }

  .rc-slider-track {
    height: 3px;
  }

  .rc-slider-rail {
    height: 3px;
  }
}
