.saps-header-search {
  position: relative;

  .saps-header-search__toggle {
    position: relative;
    cursor: pointer;
    display: block;
    z-index: 0;

    .saps-header-search__toggle-icon {
      display: block;
    }

    .saps-header-search__toggle-path {
      fill-rule: evenodd;
      fill: map-get($color-scheme-list, secondary);
      transition: all $transition-time;
    }

    &:hover {
      .saps-header-search__toggle-path {
        fill: map-get($color-scheme-list, text);
      }
    }
  }

  .saps-header-search__close {
    @extend %saps-close-cross;
  }

  .saps-header-search__form {
    padding: 0 40px;
  }

  .saps-header-search__results {
    max-height: 360px;
    overflow: auto;
    padding: 0 40px;
    position: relative;
  }

  .saps-header-results-wrapper {
    position: absolute;
    top: -10px;
    right: -16px;
    width: 320px;
    box-shadow: 3px 13px 14px 2px rgba(0, 0, 0, 0.25);
    background-color: map-get($color-scheme-list, white);
    overflow: hidden;
    max-height: 600px;
    transform: scale(0);
    transform-origin: 296px 20px;
    transition: all $transition-time;

    &.saps-header-results-wrapper--active {
      transform: scale(1);
    }
  }

  .saps-header-search__form-wrapper {
    position: relative;
    padding: 25px 0 40px;
  }

  .saps-header-search__query-input {
    display: block;
    max-width: 200px;
    font-size: 15px;
    font-weight: bold;
    padding: 2px 0;
  }

  .saps-header-search__category {
    border-bottom: solid map-get($color-scheme-list, grey-300) 1px;
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .saps-header-search__category {
    &:last-child {
      .saps-header-search__category {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .saps-header-search__category-title {
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: map-get($color-scheme-list, secondary);
    margin-bottom: 10px;
  }

  .saps-header-search__category-list {
    .saps-header-search__category-list-item {
      font-size: 15px;
      font-weight: bold;
      color: map-get($color-scheme-list, grey-300);
      display: block;
    }
  }

  .saps-header-search__item-a {
    text-decoration: none;
    color: map-get($color-scheme-list, grey-300);
    transition: all $transition-time;
    cursor: pointer;

    &:hover {
      color: map-get($color-scheme-list, secondary);
    }
  }

  .saps-header-search__suggestions {
    .saps-header-search__suggestions-list-item {
      display: block;
    }
  }
}

.saps-header-search-portlet-wrapper {
  display: none;
  padding-right: 15px;
  border-right: solid map-get($color-scheme-list, grey-300) 1px;
  margin-right: 5px;
  margin-left: 10px;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}
