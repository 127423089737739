$saps-car-padding: 30px;
$saps-car-backround: map-get($color-scheme-list, $white) !default;

.saps-car-wrapper {
  .saps-cars-list__wrapper & {
    @include make-col-ready();
    margin-bottom: 80px;

    @include media-breakpoint-up(lg) {
      @include make-col(6);
    }

    @include media-breakpoint-up(xl) {
      @include make-col(4);
    }

    @include media-breakpoint-down(md) {
      @include make-col(12);
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .saps-cars-carousel__wrapper & {
    //margin-left: 20px;
    //margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: box-shadow 0.3s ease-out;

    @include media-breakpoint-down(xs) {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }

    @include media-breakpoint-up(md) {
      margin-top: 64px;
    }
  }

  .saps-car:hover {
    @include media-breakpoint-up(lg) {
      box-shadow: 0px 0px 20px #adadad;
    }
  }
}

.saps-car {
  position: relative;

  .saps-car-default {
    background-color: #ffffff;
  }
}

.saps-car-link,
.saps-car-link:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.saps-cars-carousel__wrapper {
  @include make-container();
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
}

.saps-car__images-wrapper {
  overflow: hidden;
  position: relative;
}

.saps-car__images {
  display: flex;
  align-items: center;

  .saps-car:hover & {
    @include media-breakpoint-up(lg) {
      animation-play-state: running;
      animation-name: saps-car__images;
      animation-delay: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

@keyframes saps-car__images {
  95% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.saps-car__image {
  width: 100% !important;
  flex: 1 0 100%;
  height: 240px;
  background-size: cover;
  background-position: center;
  background-color: $saps-car-backround;
  overflow: hidden;
}

.saps-car__make {
  padding-top: 22px;
  padding-left: $saps-car-padding;
  padding-right: $saps-car-padding;
  background-color: $saps-car-backround;
}

.saps-car__model {
  padding-left: $saps-car-padding;
  padding-right: $saps-car-padding;
  background-color: $saps-car-backround;
}

.saps-car__make,
.saps-car__model {
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  color: map-get($color-scheme-list, grey-500);
}

.saps-car__cebia {
  display: flex;
  position: absolute;
  bottom: 5px;
  right: 15px;
  z-index: 1;
}

.saps-car__cebia-star {
  margin-right: 3px;
  margin-left: 3px;
  height: 22px;
}

.saps-car__model-type {
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.2px;
  color: map-get($color-scheme-list, grey-300);
  padding-left: $saps-car-padding;
  padding-right: $saps-car-padding;
  padding-top: 6px;
  background-color: $saps-car-backround;
}

.saps-car__other-properties {
  min-height: 30px;
  background-color: $saps-car-backround;
  padding-top: 8px;
  padding-bottom: 15px;
  padding-left: $saps-car-padding;
  padding-right: $saps-car-padding;
}

.saps-car__price-with-vat {
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  color: map-get($color-scheme-list, grey-500);
  padding-left: $saps-car-padding;
  padding-right: $saps-car-padding;
}

.saps-car__price {
  font-size: 13px;
  letter-spacing: 0.2px;
  color: map-get($color-scheme-list, grey-300);
  padding-left: $saps-car-padding;
  padding-right: $saps-car-padding;
}

.saps-car__prices-and-comparison {
  background-color: $saps-car-backround;
  padding-bottom: 20px;
  display: flex;
}

.saps-car__comparison {
  position: relative;
  margin-left: auto;
  padding-right: 25px;
  cursor: pointer;
  opacity: 0;
  transition: opacity linear 100ms;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .saps-car-wrapper:hover & {
    opacity: 1;
  }

  @include media-breakpoint-down(xs) {
    opacity: 1;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 15px;
  }

  @media (hover: none) {
    opacity: 1;
  }
}

.saps-car__comparison-icon-path {
  @include media-breakpoint-down(xs) {
    fill: #ef7d00;
  }
}

.saps-car__comparison-icon-path--unselected {
  @include media-breakpoint-down(xs) {
    fill: #b8b8b8;
  }
}

.saps-car__comparison-text {
  font-size: 12px;
  opacity: 1;

  @include media-breakpoint-down(xs) {
    opacity: 0;
  }
}

.saps-car__special-offer {
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: map-get($color-scheme-list, white);
  background: map-get($color-scheme-list, orange);
  padding: 8px 10px;
}

.saps-car__reservation {
  display: block;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: map-get($color-scheme-list, alertRed);
  padding: 8px 10px;
}
