.saps-errcode {
  font-weight: bold;
  font-size: 120px;

  @include media-breakpoint-up(md) {
    font-size: 240px;
    line-height: 1.2;
  }
}

.saps-404-car-wrapper {
  top: map-get($header-height, mobile);
  bottom: 0;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;

  @include media-breakpoint-up(md) {
    top: map-get($header-height, desktop);
  }
}

.saps-404-car {
  transform-origin: center;
  position: absolute;
  display: block;
  pointer-events: fill;
}

.saps-404-car__image {
  display: block;
  width: 152px;

  @include media-breakpoint-up(md) {
    width: 168px;
  }

  @include media-breakpoint-up(lg) {
    width: 196px;
  }

  @include media-breakpoint-up(xl) {
    width: 220px;
  }
}

.saps-error-page-wrapper {
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saps-error-page-wrapper--secondary {
  position: relative;
  background-color: map-get($color-scheme-list, secondary);
  overflow: hidden;

  &::after {
    display: block;
    position: absolute;
    left: 50%;
    width: 100%;
    height: 160vh;
    background-image: linear-gradient(-30deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    opacity: 0.5;
    content: '';
    transform: rotate(20deg);
  }
}
