@font-face {
  font-family: 'SKODANext';
  src:
    url('../fonts/SKODANext-Bold.woff2') format('woff2'),
    url('../fonts/SKODANext-Bold.woff') format('woff'),
    url('../fonts/SKODANext-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SKODANext';
  src:
    url('../fonts/SKODANext-Light.woff2') format('woff2'),
    url('../fonts/SKODANext-Light.woff') format('woff'),
    url('../fonts/SKODANext-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SKODANext';
  src:
    url('../fonts/SKODANext-Regular.woff2') format('woff2'),
    url('../fonts/SKODANext-Regular.woff') format('woff'),
    url('../fonts/SKODANext-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
