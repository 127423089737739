.saps-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  border-radius: 20px;
  background-color: #ffffff;
  color: map-get($color-scheme-list, text);
  box-shadow: 0 4px 7px 0 rgba(74, 74, 74, 0.2);
  padding: 6px 20px;
  cursor: pointer;
  transition: all $transition-time;
  user-select: none;

  @include media-breakpoint-up(md) {
    font-size: 10px;
  }

  &:hover {
    text-decoration: inherit;
    color: inherit;
  }

  .saps-button--with-icon-left {
    padding-left: 10px;
  }

  &.saps-button--secondary {
    background-color: map-get($color-scheme-list, secondary);
    color: map-get($color-scheme-list, white);
    box-shadow: 0 2px 4px 0 rgba(239, 125, 0, 0.26);

    &:hover {
      background-color: map-get($color-scheme-list, secondary-hover);
      box-shadow: 0 2px 4px 0 rgba(222, 116, 0, 0.26);
    }
  }

  &.saps-button--blank {
    box-shadow: none;
    background-color: transparent;
  }

  &.saps-button-grey-6--outline {
    background-color: transparent;
    border: solid map-get($color-scheme-list, grey-600) 1px;
    color: map-get($color-scheme-list, grey-600);
  }

  &.saps-button-orange-text {
    color: map-get($color-scheme-list, orange);
  }

  &.saps-button--link {
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    font-size: 10px;
  }

  &:disabled {
    background-color: map-get($color-scheme-list, grey-100);
    color: map-get($color-scheme-list, grey-410);
    box-shadow: none;
    pointer-events: none;
    cursor: default;
  }

  &.saps-button--fullwidth {
    display: flex;
    width: 100%;
  }

  .saps-car-list__filter-tag-wrapper & {
    height: 40px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.saps-linkButton,
.saps-linkButton:hover {
  color: inherit;
  text-decoration: none;
}

@include responsive_class('button--50-tall') {
  min-height: 50px;
  border-radius: 25px;
}

@include responsive_class('button--40-tall') {
  min-height: 40px;
}
