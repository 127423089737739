.saps-car-carousel {
  display: flex;
  max-width: 920px;
  flex-wrap: wrap;
  max-height: 265px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    max-height: 545px;
  }

  .car-thumbnail--container {
    position: relative;
    flex: 1 1 auto;
    .car-thumbnail-swiper {
      position: absolute;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

//Big slider
.car-gallery-swiper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  padding: 0;
  z-index: 1;
  max-height: 265px;
  flex: 0 0 100%;

  @include media-breakpoint-up(md) {
    flex: 0 0 80%;
    max-height: 545px;
  }
}

.saps-car-gallery-prev {
  left: 0;

  @include media-breakpoint-up(md) {
    left: auto;
    right: 100%;
  }
}

.saps-car-gallery-next {
  right: 0;

  @include media-breakpoint-up(md) {
    left: 100%;
    right: auto;
  }
}

//Thumbs slider
.car-thumbnail-swiper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;

  @include media-breakpoint-up(md) {
    max-height: 100%;
  }
}

.saps-thumbnail-slide {
  // flex-shrink: 0;
  // position: relative;
  // transition-property: transform;

  // max-width: 100%;
  // width: auto;
  // height: auto;
  height: 50px;
}
