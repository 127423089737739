@each $value in $spacing-list {
  @include responsive_class('m-#{nth($value, 1)}') {
    margin: nth($value, 1) + px;
  }

  @include responsive_class('mt-#{nth($value, 1)}') {
    margin-top: nth($value, 1) + px;
  }

  @include responsive_class('mr-#{nth($value, 1)}') {
    margin-right: nth($value, 1) + px;
  }

  @include responsive_class('mb-#{nth($value, 1)}') {
    margin-bottom: nth($value, 1) + px;
  }

  @include responsive_class('ml-#{nth($value, 1)}') {
    margin-left: nth($value, 1) + px;
  }

  @include responsive_class('p-#{nth($value, 1)}') {
    padding: nth($value, 1) + px;
  }

  @include responsive_class('pt-#{nth($value, 1)}') {
    padding-top: nth($value, 1) + px;
  }

  @include responsive_class('pr-#{nth($value, 1)}') {
    padding-right: nth($value, 1) + px;
  }

  @include responsive_class('pb-#{nth($value, 1)}') {
    padding-bottom: nth($value, 1) + px;
  }

  @include responsive_class('pl-#{nth($value, 1)}') {
    padding-left: nth($value, 1) + px;
  }
}

.saps-h100 {
  height: 100%;
}

.saps-w100 {
  width: 100%;
}

.saps-hr {
  width: 100%;
  height: 0;
  background: none;
  border-top: solid map-get($color-scheme-list, grey-100) 1px;
}

.saps-vr {
  width: 1px;
  height: 100%;
  background-color: map-get($color-scheme-list, grey-100);
}

@each $value in $color-scheme-list {
  @include responsive_class('border-color-#{nth($value, 1)}') {
    border-color: map-get($color-scheme-list, nth($value, 1));
  }

  @include responsive_class('color-#{nth($value, 1)}') {
    color: map-get($color-scheme-list, nth($value, 1));
  }

  @include responsive_class('bg-#{nth($value, 1)}') {
    background-color: map-get($color-scheme-list, nth($value, 1));
  }
}

@include responsive_class('bold') {
  font-weight: bold;
}

@include responsive_class('light') {
  font-weight: bold;
}

@include responsive_class('normal') {
  font-weight: normal;
}

@include responsive_class('border-right') {
  border-right: solid map-get($color-scheme-list, grey-100) 1px;
}

@include responsive_class('border-left') {
  border-left: solid map-get($color-scheme-list, grey-100) 1px;
}

.saps-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
