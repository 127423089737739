@mixin responsive_class($className) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .saps-#{$className}#{$infix} {
      @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @content;
      }
    }
  }
}
