.saps-cars-list__wrapper {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;

  @include media-breakpoint-up(lg) {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 1100px;
  }
}

.saps-cars-list {
  @include make-row();
}

[id='splus-cars-list-small-filter'] {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.saps-small-filter__checkboxes {
  display: flex;
  // justify-content: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex: 1 1 auto;

  @include media-breakpoint-up(lg) {
    flex: 0 1 auto;
    margin-left: auto;
    min-width: 500px;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
}

.saps-small-filter__checkboxes-submodule {
  justify-content: flex-start !important;

  @include media-breakpoint-up(xl) {
    justify-content: space-between;
  }
}
.saps-small-filter__checkboxes-detail-submodule {
  justify-content: flex-start !important;

  @include media-breakpoint-up(xl) {
    justify-content: space-between;
  }
}

.saps-small-filter__checkbox {
  margin-bottom: 0;
  padding-left: 5px;
  padding-right: 5px;

  &:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
    margin-top: 18px;
  }
}

.saps-small-filter__checkbox:last-child {
  @include media-breakpoint-up(sm) {
    margin-right: 0px;
  }
}

//.saps-cars-list-small-filter-sticky--fixed {
//    position: fixed;
//    left: 340px;
//    right: 0;
//    background-color: map-get($color-scheme-list, grey-50);
//    z-index: 2;
//    padding-left: 40px;
//    padding-right: 40px;
//
//    //@media (min-width: 1400px) {
//    //    left: calc((100% - 1440px) / 2 + 340px);
//    //    right: calc((100% - 1440px) / 2);
//    //}
//
//    @include media-breakpoint-only(xs) {
//        display: none;
//    }
//
//    @include media-breakpoint-up(sm) {
//        top: 60px;
//    }
//
//    @include media-breakpoint-up(lg) {
//        top: map-get($header-height, desktop);
//    }
//}

.saps-car-list-wrapper--has-sticky-filter {
  padding-top: 90px;
}
