.saps-watchdog {
  //max-width: 920px;
  max-height: 100%;
  overflow: auto;
  background-color: map-get($color-scheme-list, white);
  padding: 30px;

  @include media-breakpoint-up(md) {
    padding: 40px 60px;
  }

  @include media-breakpoint-up(lg) {
    padding: 80px 120px;
  }

  .saps-lightbox__content-wrapper {
    &::before {
      display: none;
    }
  }
}
