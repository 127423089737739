.saps-label {
  display: block;
  font-size: 13px;
  letter-spacing: 0.2px;
  font-weight: normal;

  .saps-cars-list-filter & {
    @include media-breakpoint-down(sm) {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.saps-label-small {
  font-size: 10px;
  color: map-get($color-scheme-list, grey-300);
}

.saps-inputlist-dropdown {
  position: relative;
  display: block;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .saps-cars-list-filter & {
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }

  .saps-inputlist-dropdown__list-wrapper {
    display: none;
    position: absolute;
    background-color: map-get($color-scheme-list, white);
    top: 100%;
    min-width: 100%;
    max-height: 400px;
    overflow: auto;
    z-index: 11;
    padding: 18px 0px;
    box-shadow: 0 4px 14px 3px rgba(0, 0, 0, 0.1);

    &.saps-dialog-active {
      display: block;
    }

    .saps-inputlist-dropdown__list-item {
      display: block;
      padding: 5px 20px;

      .saps-inputlist-dropdown__list-item-label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        transition: all $transition-time;

        .saps-car-filter & {
          @include media-breakpoint-down(sm) {
            justify-content: center;
          }
        }

        &:hover {
          .saps-checkbox-title {
            color: map-get($color-scheme-list, secondary);
          }
        }

        .saps-input-checkbox {
          display: block;
          flex: 0 0 auto;
          margin: 5px 12px 0 0;
          border: solid map-get($color-scheme-list, grey-300);

          &[type='radio'] {
            display: none;
          }

          &:checked {
            border-color: map-get($color-scheme-list, secondary);

            ~ .saps-checkbox-title {
              color: map-get($color-scheme-list, secondary);
            }
          }
        }

        .saps-checkbox-title {
          flex: 0 1 auto;
          font-size: 15px;
          color: map-get($color-scheme-list, grey-400);
          letter-spacing: 0.5px;
        }
      }
    }
  }

  &.saps-inputlist-dropdown--grey-triangle {
    .saps-inputlist-dropdown__toggle {
      border-bottom: none;

      .saps-inputlist-dropdown__toggle-text {
        font-size: 15px;
        font-weight: 300;
      }
    }
  }
}

.saps-inputlist-dropdown__list {
  margin-bottom: 0;
}

.saps-inputlist-dropdown__toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: solid map-get($color-scheme-list, grey-500) 1px;
  padding: 5px 0;

  .saps-cars-list-filter & {
    @include media-breakpoint-down(sm) {
      padding: 23px 25px;
      background-color: #fff;
      border-bottom: 0;
    }
  }

  .saps-inputlist-dropdown__toggle-text {
    font-size: 13px;
    font-weight: bold;
    flex: 1 0 auto;
    margin-right: 5px;

    .saps-cars-list-filter & {
      @include media-breakpoint-down(sm) {
        font-weight: bold;
        font-size: 16px;
      }
    }

    &.saps-inputlist-dropdown__toggle-text--radio {
      font-weight: 300;

      .saps-button & {
        font-weight: 600;
      }
    }
  }

  .saps-button & {
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.saps-inputlist-dropdown__toggle-icon {
  flex: 0 0 auto;

  .saps-inputlist-dropdown--grey-triangle & {
    svg {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6.9px 4px 0 4px;
      border-color: #6b6b6b transparent transparent transparent;
    }
  }

  .saps-button & {
    display: none;
  }
}

.saps-input-slider {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }

  .saps-cars-list-filter & {
    @include media-breakpoint-down(sm) {
      background-color: #fff;
      padding: 23px 25px;
      margin-bottom: 10px;
    }
  }

  .saps-input-slider__header {
    margin-bottom: 15px;
  }

  .saps-slider-range {
    display: flex;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;

    .saps-cars-list-filter & {
      @include media-breakpoint-down(sm) {
        text-align: center;
        border-top: 1px solid #e2e2e2;
        margin-top: 20px;
        padding-top: 20px;
        font-size: 16px !important;
        font-weight: 300;
      }
    }

    .saps-slider-range__border {
      display: flex;
      flex: 0 0 auto;
      padding: 0 2px 9px 2px;
      line-height: 1;
      background-repeat: repeat-x;
      background-position: left bottom;
      background-size: 6px 2px;
      background-image: linear-gradient(
        90deg,
        transparent 0,
        transparent 2px,
        map-get($color-scheme-list, secondary) 2px,
        map-get($color-scheme-list, secondary) 4px,
        transparent 4px,
        transparent 6px
      );

      .saps-slider-range__border-input-wrapper {
        flex: 0 0 auto;
      }

      .saps-slider-range__border-input {
        display: block;
        width: 110px;
        font: inherit;
        padding: 0;
        text-align: right;
        @include media-breakpoint-up(md) {
          width: 150px;
        }
      }

      .saps-slider-range__border-input--right {
        width: 150px;
        @include media-breakpoint-up(md) {
          width: 150px;
        }
      }

      .saps-slider-range__units {
        flex: 0 0 auto;
        margin-left: 10px;
      }
      .saps-slider-range__units-wrapper {
        .saps-slider-range__units {
          vertical-align: middle;
        }
      }
    }
  }

  &.saps-input-slider-small {
    .saps-input-slider__header {
      margin-bottom: 20px;
    }

    .saps-slider-range {
      font-size: 13px;

      .saps-slider-range__border-input {
        width: 76px;
      }

      .saps-slider-range__border-input--right {
        width: 100px;
        @include media-breakpoint-up(md) {
          width: 88px;
        }
      }

      .saps-slider-range__units {
        font-weight: 300;
      }

      .saps-slider-range__units-wrapper {
        .saps-slider-range__units {
          vertical-align: middle;
        }
      }
    }

    .rc-slider-handle {
      border: none;
      width: 20px;
      height: 20px;
      margin-top: -9px;
      margin-left: -10px;
      background-color: map-get($color-scheme-list, white);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    }

    .rc-slider {
      &::before {
        display: none;
      }
    }
  }

  &.saps-input-slider--purpose-filter {
    .saps-slider-range {
      font-size: 20px;
      line-height: 1.1;

      @include media-breakpoint-up(md) {
        font-size: 15px;
        line-height: 1.47;
      }
    }

    .rc-slider {
      @include media-breakpoint-up(md) {
        .rc-slider-rail {
          height: 5px;
        }

        .rc-slider-track {
          height: 5px;
        }
      }
    }
  }
}

.saps-removable-tag {
  $tagLineHeight: 16px;

  display: inline-flex;
  align-items: center;
  font-size: 10px;
  line-height: $tagLineHeight;
  font-weight: bold;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: map-get($color-scheme-list, secondary);
  min-height: 40px;
  border-radius: 20px;
  background-color: map-get($color-scheme-list, white);
  box-shadow: 0 4px 7px 0 rgba(74, 74, 74, 0.2);
  padding: 5px 15px 5px 30px;

  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: 3px;

  @include media-breakpoint-up(md) {
    margin-right: 30px;
  }

  .saps-removable-tag__remove {
    font-size: 19px;
    line-height: $tagLineHeight;
    color: map-get($color-scheme-list, text);
    cursor: pointer;
    transition: all $transition-time;
    padding: 7px;
    position: relative;
    top: 1px;

    &:hover {
      color: map-get($color-scheme-list, secondary);
    }
  }
}

.saps-checkbox-basic {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all $transition-time;
  margin: 0;

  &:hover {
    color: map-get($color-scheme-list, secondary);

    .saps-checkbox-basic__checkbox {
      border-color: map-get($color-scheme-list, secondary);
    }
  }

  .saps-checkbox-basic__checkbox {
    display: block;
    width: 20px;
    height: 20px;
    border: solid map-get($color-scheme-list, grey-300) 1px;
    background-position: center;
    background-size: 70% 70%;
    background-repeat: no-repeat;
    background-color: map-get($color-scheme-list, white);
    margin-right: 10px;
    transition: all $transition-time;
    flex: 0 0 20px;
  }

  .saps-checkbox-basic__input {
    display: none;
  }

  .saps-checkbox-basic__input:checked ~ .saps-checkbox-basic__checkbox {
    border-color: map-get($color-scheme-list, secondary);
    background-image: url('data:image/svg+xml;charset=UTF-8,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3e%3cpath fill="%23ef7d00" d="M173.9 439.4L7.5 273c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 312.69l240.1-240.1c10-10 26.2-10 36.2 0l36.2 36.21c10 10 10 26.2 0 36.2L210.1 439.4c-10 10-26.2 10-36.2 0z"/%3e%3c/svg%3e');
  }
}

.saps-checkbox-basic__text {
  line-height: 1.1;
}

.saps-checkbox-basic-tooltip {
  position: relative;
  display: flex;
  color: #b8b8b8;
  padding-top: 2px;
  padding-left: 30px;
  margin-bottom: 0;
}

.saps-input-basic {
  position: relative;
  display: block;
  padding-top: 12px;
  margin-bottom: 0;

  &.saps-input-basic--invalid {
    .saps-input-basic__input {
      border-bottom-color: #cc0000;
      color: #cc0000;
    }

    .saps-input-basic__label {
      color: #cc0000;
    }
  }
}

.saps-input-jsvalidated {
  position: relative;
  display: block;
  padding-top: 12px;
  margin-bottom: 0;
}

.saps-input-basic__input {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.85;
  padding: 5px 0 7px 0;
  border-bottom: solid map-get($color-scheme-list, text) 1px;

  &::placeholder {
    color: map-get($color-scheme-list, text);
    opacity: 1;
  }

  &:focus,
  &:active,
  &:valid {
    ~ .saps-input-basic__label {
      top: 0;
      font-size: 10px;
      color: map-get($color-scheme-list, grey-300);
    }
  }
}

.saps-input-basic__label {
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 13px;
  font-weight: 300;
  color: map-get($color-scheme-list, text);
  transition: all $transition-time;
}

.saps-input-jsvalidated__input {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.85;
  padding: 5px 0 7px 0;
  border-bottom: solid map-get($color-scheme-list, text) 1px;

  &::placeholder {
    color: map-get($color-scheme-list, text);
    opacity: 1;
  }

  &:focus,
  &:active,
  &[data-state='valid'] {
    ~ .saps-input-jsvalidated__label {
      top: 0;
      font-size: 10px;
      color: map-get($color-scheme-list, grey-300);
    }
  }

  &[data-state='invalid'] {
    border-bottom-color: #cc0000;
    color: #cc0000;

    ~ .saps-input-jsvalidated__label {
      top: 0;
      font-size: 10px;
      color: #cc0000;
    }
  }

  &[data-state='invalid-empty'] {
    border-bottom-color: #cc0000;
    color: #cc0000;

    ~ .saps-input-jsvalidated__label {
      color: #cc0000;
    }
  }
}

.saps-input-jsvalidated__label {
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 13px;
  font-weight: 300;
  color: map-get($color-scheme-list, text);
  transition: all $transition-time;
}

.saps-textarea {
  display: block;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  background-color: map-get($color-scheme-list, grey-50);
  color: map-get($color-scheme-list, text);
  padding: 4px 9px;
  resize: none;

  &::placeholder {
    opacity: 1;
    color: map-get($color-scheme-list, text);
  }

  &.saps-textarea--invalid {
    color: #cc0000;

    &::placeholder {
      color: #cc0000;
    }
  }
}

.saps-input-checkbox-color__wrapper {
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.saps-input-checkbox-color__circle {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.saps-input-checkbox-color__circle_checked {
  box-shadow: 0 0 15px #c5c5c5;
}

.saps-input-checkbox-color__check-wrapper {
  width: 25px;
  height: 25px;
  background-color: map-get($color-scheme-list, orange);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: -4px;
  right: -4px;
}

.saps-input-checkbox-color__check-icon {
  fill: #eeeeee;
}

.saps-label-radio {
  display: flex;
  position: relative;
  cursor: pointer;

  .saps-input-radio__dot {
    display: block;
    position: relative;
    top: 2px;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border: solid map-get($color-scheme-list, grey-300) 1px;
    border-radius: 50%;
    margin-right: 12px;

    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: map-get($color-scheme-list, secondary);
      border-radius: 50%;
      opacity: 0;
      transform: scale(0);
      content: '';
      transition: all $transition-time;
      margin-top: -5px;
      margin-left: -5px;
    }
  }

  .saps-input-radio {
    display: none;

    &:checked {
      ~ .saps-input-radio__dot {
        &::before {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.saps-input-upload {
  display: none;
}

.saps-input-upload__text {
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 1.2px;
  color: map-get($color-scheme-list, secondary);
  cursor: pointer;
  transition: all $transition-time;

  &:hover {
    color: map-get($color-scheme-list, text);
  }
}
