//.saps-car-filter {
//    $carFilterBreakpoint: lg;
//    @include media-breakpoint-up($carFilterBreakpoint) {
//        display: flex;
//        align-items: stretch;
//        overflow-x: hidden;
//    }
//
//    .car-list-filter-wrapper {
//        max-width: 340px;
//    }
//
//    .saps-car-filter__filter {
//        display: block;
//        position: fixed;
//        top: 0;
//        left: 0;
//        width: 100%;
//        max-height: 100vh;
//        overflow: hidden;
//        transform: scale(0);
//        transform-origin: center top;
//        transition: all $transition-time;
//        z-index: 12;
//        background-color: map-get($color-scheme-list, grey-50);
//
//        &.saps-dialog-active {
//            transform: none;
//        }
//
//        @include media-breakpoint-up($carFilterBreakpoint) {
//            overflow: auto;
//            flex: 0 0 340px;
//            position: relative;
//            max-height: none;
//            width: 340px;
//            transform: none;
//            background-image: linear-gradient(
//                270deg,
//                map-get($color-scheme-list, grey-100),
//                transparent 68px,
//                transparent
//            );
//            z-index: 1;
//        }
//
//        .saps-car-filter__filters-wrapper {
//            @include media-breakpoint-up($carFilterBreakpoint) {
//                display: flex;
//                flex-wrap: nowrap;
//            }
//        }
//
//        .saps-car-filter__filter-container {
//            width: 100%;
//            position: relative;
//            max-height: 100vh;
//            overflow: auto;
//
//            @include media-breakpoint-up($carFilterBreakpoint) {
//                flex: 1 0 340px;
//                max-height: unset;
//                max-width: 340px;
//                overflow: hidden;
//            }
//
//            @include media-breakpoint-up($carFilterBreakpoint) {
//                max-height: none;
//            }
//        }
//
//        .saps-car-filter__full-filter-container {
//            flex: 1 0 auto;
//            transform: scaleX(0);
//            max-height: 0;
//
//            @include media-breakpoint-up(sm) {
//                width: calc(100% - 340px);
//            }
//        }
//    }
//
//    .saps-car-filter__main {
//        flex: 1 1 auto;
//
//        .saps-car-wrapper {
//            @include make-col(12);
//
//            @include media-breakpoint-up(lg) {
//                @include make-col(6);
//            }
//
//            @include media-breakpoint-up(xl) {
//                @include make-col(4);
//            }
//        }
//    }
//
//    .saps-car-filter__filter-title {
//        font-size: 22px;
//        font-weight: bold;
//        line-height: 1;
//    }
//
//    .saps-cars-list-filter {
//        margin-bottom: 120px;
//
//        @include media-breakpoint-up($carFilterBreakpoint) {
//            margin-bottom: 180px;
//        }
//
//        .saps-cars-list-filter__actions {
//            position: fixed;
//            max-width: 1440px;
//            width: 100%;
//            left: 0;
//            bottom: 0;
//            height: 80px;
//            z-index: 11;
//            transition: all $transition-time;
//
//            @include media-breakpoint-up($carFilterBreakpoint) {
//                left: auto;
//                margin-left: -40px;
//                height: 150px;
//                width: 340px;
//                background: map-get($color-scheme-list, white);
//            }
//
//            .saps-car-list-filter__submit {
//                width: 100%;
//                height: 100px;
//                flex-flow: column wrap;
//                background-color: map-get($color-scheme-list, secondary);
//                display: flex;
//                align-items: center;
//                justify-content: center;
//                text-decoration: none;
//                cursor: pointer;
//
//                .saps-car-list-filter__submit-text {
//                    font-size: 22px;
//                    font-weight: bold;
//                }
//            }
//        }
//    }
//
//    &.saps-car-filter--full {
//        .saps-car-filter__filter {
//            flex: 1 0 calc(100% - 40px);
//        }
//
//        .saps-car-filter__main {
//            transform: scaleX(0);
//        }
//
//        .saps-car-filter__full-filter-container {
//            flex: 1 0 auto;
//            transform: scaleX(1);
//            max-height: 3000px;
//        }
//
//        .saps-cars-list-filter__actions {
//            width: 100%;
//        }
//    }
//}
//
//.saps-car-filter--hidden {
//    height: 0;
//    overflow: hidden;
//}
//
//.saps-car-list__filter-tag-wrapper {
//    display: flex;
//    flex-wrap: wrap;
//}
//
//.saps-map-region {
//    fill: map-get($color-scheme-list, grey-300);
//    transition: all $transition-time;
//
//    &[data-checked="true"],
//    &:hover {
//        fill: map-get($color-scheme-list, secondary);
//    }
//
//    &[data-checked="false"] {
//        fill: map-get($color-scheme-list, grey-300);
//    }
//
//    @include media-breakpoint-up(xl) {
//        &[data-checked="true"]:hover {
//            opacity: 0.5;
//        }
//    }
//
//    @include media-breakpoint-down(sm) {
//        stroke: map-get($color-scheme-list, grey-100);
//        stroke-width: 1;
//    }
//}
//
//
//.saps-cars-list-small-filter--submodule {
//    padding-left: 0;
//    padding-right: 0;
//    padding-top: 0;
//}

$carFilterBreakpoint: md;

.saps-car-filter {
  @include media-breakpoint-up($carFilterBreakpoint) {
    display: flex;
  }
}

.saps-car-filter__filters-wrapper {
  @include media-breakpoint-up($carFilterBreakpoint) {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
  }
}

//.saps-car-filter__filter-container {
//    width: 100%;
//    max-height: 100vh;
//    overflow: auto;
//
//    @include media-breakpoint-up($carFilterBreakpoint) {
//        flex: 1 0 340px;
//        max-height: unset;
//        max-width: 340px;
//        overflow: hidden;
//        max-height: none;
//    }
//}

.saps-car-filter__full-filter-container {
  flex: 1 0 auto;

  @include media-breakpoint-up(sm) {
    width: calc(100% - 340px);
  }
}

.saps-car-filter__filters-wrapper {
  display: flex;
}

//.saps-car-filter__filter-container {
//    position: relative;
//    z-index: 3;
//    margin-right: 0px;
//    padding: 70px 70px 80px 70px;
//    background-color: #f7f7f7;
//    flex: 1 0 340px;
//    max-height: unset;
//    max-width: 340px;
//    overflow: hidden;
//    //background-image: linear-gradient(270deg, #eaeaea, transparent 35px, transparent);
//}

.saps-car-filter__filter-container {
  @include media-breakpoint-up(md) {
    z-index: 4;
    margin-right: 40px;
    padding: 70px 0 80px 70px;
    background-color: #f7f7f7;
    flex: 1 0 300px;
    max-height: unset;
    max-width: 300px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
    padding: 0px 20px;
    flex: 0 0 100%;
  }
}

.saps-car-filter__filter-container_active {
  //background-image: none;
}

.saps-car-filter__full-filter-container {
  transform: translateX(-100%);
  z-index: 3;
  background-color: #f7f7f7;
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  width: calc(100vw - 340px - 40px);
  // max-width: calc(1440px - 340px);
  max-width: 1100px;
  visibility: hidden;
}

.saps-car-filter__full-filter-container:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 100%;
  background-image: linear-gradient(270deg, #eaeaea, transparent 35px, transparent);
  visibility: visible;
}

.saps-car-filter__full-filter-container_active {
  transform: translateX(0);
  visibility: visible;
}

.saps-car-filter__filter {
  //width: 340px;
  //width: auto;
  @include media-breakpoint-up(md) {
    overflow-x: hidden;
    flex: 1 1;
  }
}

.saps-car-filter__main {
  width: calc(100% - 340px);
}

.saps-car-filter__main_active {
  display: none;
}

.saps-cars-list-filter__toolbar {
  position: -webkit-sticky;
  position: sticky;
  z-index: 4;
  bottom: 0;
  width: 340px;
  @include media-breakpoint-down(sm) {
    width: inherit;
  }
}

.saps-cars-list-filter__actions {
  transition: all 0.15s cubic-bezier(0.65, 0.05, 0.36, 1);
  background: #fff;
  width: 340px;
  z-index: 2;
  position: relative;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.saps-cars-list-filter__actions_active {
  width: 100%;
}

.saps-cars-list-filter__button-advanced-filter {
  max-width: 340px;
}

.saps-map-region {
  fill: map-get($color-scheme-list, grey-300);
  transition: all $transition-time;

  &[data-checked='true'],
  &:hover {
    fill: map-get($color-scheme-list, secondary);
  }

  &[data-checked='false'] {
    fill: map-get($color-scheme-list, grey-300);
  }

  @include media-breakpoint-up(xl) {
    &[data-checked='true']:hover {
      opacity: 0.5;
    }
  }

  @include media-breakpoint-down(sm) {
    stroke: map-get($color-scheme-list, grey-100);
    stroke-width: 1;
  }
}

.saps-cars-list-small-filter {
  padding-left: 40px;
  padding-right: 40px;
}

.saps-car-list-small-filter-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  @include media-breakpoint-up(sm) {
    position: sticky;
    top: 60px;
    background: #f7f7f7;
    z-index: 2;
    padding-bottom: 4px;
    padding-top: 4px;
  }

  @include media-breakpoint-up(lg) {
    top: 80px;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.saps-loading__wrapper {
  min-height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saps-car-filter__filter-title {
  font-size: 22px;
  font-weight: bold;
  line-height: 1;
}

.saps-car-filter__filter-modal-button-wrapper {
  position: -webkit-sticky;
  position: sticky;
  bottom: 40px;
}

.saps-car-list__filter-tag-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;

  @include media-breakpoint-up(lg) {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.saps-region {
  @include media-breakpoint-down(sm) {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 25px;
  }
}
