$grid-gutter-width: 20px !default;

@import 'libs/bootstrap/functions';
@import 'libs/bootstrap/variables';
@import 'libs/bootstrap/mixins';
@import 'libs/bootstrap/root';

// use/uncomment next line only when not on liferay (e.g. when developing react components on webpack)
@import 'imports/clay';

@import 'libs/slick/slick';
@import 'libs/rc-slider/rc-slider';
@import 'libs/swiper/swiper';
@import 'libs/react-tabs/react-tabs';

@import 'imports/vars';
@import 'imports/grid';
@import 'imports/mixins';
@import 'imports/liferay-reset';
@import 'imports/libs-overwrite';

@import 'imports/fonts';
@import 'imports/global';
@import 'imports/layout';
@import 'imports/ui-kit';
@import 'imports/header';
@import 'imports/footer';
@import 'imports/lightbox';
@import 'imports/blocks';
@import 'imports/helpers';
@import 'imports/error-page';

@import 'components/basic-filter';
@import 'components/recommended-models';
@import 'components/cars-carousel';
@import 'components/car-carousel';
@import 'components/cars-list';
@import 'components/car';
@import 'components/car-detail';
@import 'components/basic-cars-filter';
@import 'components/tabs';
@import 'components/header-search';
@import 'components/dealer-detail';
@import 'components/dealer-list';
@import 'components/cars-comparison';
@import 'components/brands';
@import 'components/watchdog';
@import 'components/modal';
@import 'components/mother-card';
@import 'components/input';

html {
  //overflow: auto;
}

body {
  font-weight: 300;
  font-size: 15px;
  color: map-get($color-scheme-list, text);
  background-color: #f7f7f7;
  line-height: 1.6;
  text-align: left;
  padding-top: map-get($header-height, mobile);
  overflow-x: hidden;

  @include media-breakpoint-up(lg) {
    padding-top: map-get($header-height, desktop);
  }
}

#wrapper {
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
}

.portlet {
  display: flex;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.saps-inputlist-dropdown__toggle-icon {
  width: 18px;

  @include media-breakpoint-up(md) {
    width: 13px;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

p {
  margin-bottom: 0;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
